<template>
  <div class="navDashboard" v-animate-css="'slideInDown'" style="background: #141414;">
    <div class="container-nav-dashboard d-flex flex-row">
      <div class="logo-dashboard xs">
        <img src="/img/logo/logo-min4.png" alt="" class="img-fluid" />
      </div>
      <div class="title-label lg">
        <i class="fab fa-modx mr-1"></i>
        {{
          $store.state.user.type == "admin"
          ? "Panel administrativo"
          : $store.state.user.type == "gamer"
            ? "Panel Gamer"
            : "Creador de contenido"
        }}
      </div>
      <div class="menu-nav-dashboard d-flex flex-row">




        <div class="dropdown noti">

          <!-- XS  -->
          <div @click.prevent="$store.state.notificacionesActive = true, ValidateNoti()" class="notificaciones xs"
            v-if="$store.state.user && $store.state.user.type == 'influencer'" style="
                          ">
            <i v-if="$store.state.alertNotificacion" class="fas fa-circle"
              style="position: absolute; font-size: 7px; color: #f96c6c; right: 6px"></i>
            <!-- <i class="fas fa-bell" style="color: rgb(217 217 217)">
              {{ $store.state.countNotificacion }}
            </i> -->
            <i class="fas fa-bell" style="color: rgb(217 217 217)">
            <div
              class="number-noti"
              style="position: absolute; font-size: 15px; top: 0px; right: 0px"
            >
              {{ $store.state.countNotificacion }}
            </div>
          </i>

          </div>

          <!-- LG  -->
          <div class="notificaciones lg" data-toggle="dropdown" aria-expanded="false" @click.prevent="ValidateNoti()"
            v-if="$store.state.user && $store.state.user.type == 'influencer'">
            <i v-if="$store.state.alertNotificacion" class="fas fa-circle"
              style="position: absolute; font-size: 7px; color: #f96c6c; right: 6px"></i>
            <!-- <i class="fas fa-bell" style="color: rgb(217 217 217)">
              {{ $store.state.countNotificacion }}
            </i> -->
            <i class="fas fa-bell" style="color: rgb(217 217 217)">
            <div
              class="number-noti"
              style="position: absolute; font-size: 15px; top: 0px; right: 0px"
            >
              {{ $store.state.countNotificacion }}
            </div>
          </i>

          </div>

          <div class="dropdown-menu">
            <div class="title">Notificaciones</div>
            <br />
            <template v-if="$store.state.notificaciones[0]">
              <div class="notificacion-item"
                style="position: relative; padding: 10px; border-bottom: 1px solid rgb(46 46 46)"
                v-for="(item, index) in $store.state.notificaciones" :key="index"
                @click="item.type == 1 ? irTorneo(item.evento_id) : item.type == 2 ? irProfile(item.evento_id) : null">
                <div class="d-flex flex-row">
                  <div class="avatar-logo-img">
                    <template v-if="item.type == 2">
                      <div class="img-avatar" v-if="item.user && item.user.avatar"
                        :style="{ 'background-image': 'url(' + $base.server + item.user.avatar + ')' }"></div>
                      <div class="img-avatar" v-if="item.user && !item.user.avatar" :style="{
                        'background-image': 'url(/img/iconos/avatar.png)',
                      }">

                      </div>
                    </template>
                    <template v-else>
                      <div class="img-torneo" v-if="item.torneo && item.torneo.juego"
                        :style="{ 'background-image': 'url(' + $base.server + item.torneo.juego.miniatura + ')' }"></div>
                    </template>

                  </div>
                  <div class="div">
                    <div class="title-item" style="font-size: 12px; font-weight: 900">
                      {{ item.titulo }}
                    </div>
                    <div class="body" style="font-size: 12px;" v-html="item.body"></div>

                    <small style="
                                      font-size: 11px;
                                      font-weight: 500;
                                      color: #919191;
                                      right: 0;
                                      float: right;
                                      margin-top: 0px;
                                      margin-bottom: 11px;
                                      position: absolute;
                                      right: 18px;
                                      top: 0;
                                    ">{{ moment(item.created_at).fromNow() }}</small>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text">
                No tienes notificaciones
              </div>
            </template>
          </div>
        </div>


        <div class="menu-avatar lg">
          <div class="dropdown">
            <div class="d-flex flex-row" type="button" style="align-items:center" data-toggle="dropdown"
              aria-expanded="false" v-if="$store.state.user">
              <div class="nombre-user lg">{{ $store.state.user.name }}</div>
              <div class="avatar " v-if="!$store.state.user.avatar" :style="{
                'background-image': 'url(/img/iconos/avatar.png)',
              }">

              </div>
              <div class="avatar d-flex" v-if="$store.state.user.avatar" :style="{
                'background-image':
                  'url(' + $base.server + $store.state.user.avatar + ')',
              }"></div>
            </div>
            <div class="dropdown-menu">
              <div class="tipo" style="color: #fff">
                {{
                  $store.state.user.type == "admin"
                  ? "Administrador"
                  : $store.state.user.type == "gamer"
                    ? "Gamer"
                    : "Creador de contenido"
                }}
              </div>
              <template v-if="$store.state.user.type != 'admin'">
                <router-link :to="{ name: 'cuentaInfluencer' }">
                  <a style="color: #fff" class="dropdown-item" href="#">Mi cuenta</a>
                </router-link>
                <hr />
              </template>
              <a style="color: #fff" class="dropdown-item" href="#" @click.prevent="logout()">Cerrar Sesión <i
                  class="fas fa-sign-out ml-2"></i></a>
            </div>
          </div>
        </div>

        <div class="menuSidebar xs" @click.prevent="$store.state.sidebarActive = true">
          <svg role="img" height="24" width="24" aria-hidden="true" viewBox="0 0 24 24" data-encore-id="icon"
            class="Svg-sc-ytk21e-0 gQUQL">
            <path d="M21 6H3V4h18v2zm0 14H3v-2h18v2zm0-7H3v-2h18v2z"></path>
          </svg>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    irTorneo(torneo) {
      if (this.$route.name == "ChampionshipListaTorneos") {
        location.href = "/championship/" + torneo + "/torneos/lista";
      } else {
        this.$router.push({ path: "/championship/" + torneo + "/torneos/lista" });
      }
    },
    irProfile(username) {
      if (this.$route.name == "perfilUser") {
        location.href = "/" + username;
      } else {
        this.$router.push({ path: "/" + username });
      }
    },
    logout() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href = "/";
        })
        .catch((error) => {
          toastr.error("Por favor recargue la pagina");
        });
    },
    ValidateNoti() {
      let url = this.$base.dominio + '/notificaciones/user';
      axios.post(url).then(res => {
        var countserve = res.data.length;
        localStorage.setItem('countlocal', countserve);
        this.$store.state.alertNotificacion = false;
        this.$store.state.countNotificacion = null;
      }).catch(e => {
        console.log(`error al cargar las notificaciones`);
      })
    }
  },
};
</script>
<style lang="scss" scoped>
@media(max-width:768px) {
  .menuSidebar svg {
    fill: white;
    right: 21px;
    margin-top: 0px;
    margin-left: 17px;
    margin-right: 0px;
  }


  .navDashboard {
    padding-left: 25px !important;
    padding-right: 25px !important;

    .notificaciones {
      margin-right: 18px !important;
      cursor: pointer;
      width: 39px;
      font-size: 23px;
      color: rgb(54, 54, 54);
      position: relative;
    }
  }
}
</style>
<style lang="scss" scoped>
.notificaciones {
  margin-right: 95px;
  cursor: pointer;
  width: 39px;
  font-size: 23px;
  color: #363636;
  position: relative;
}



.title-label {
  color: white;

}

.noti {

  .img-torneo {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background-size: cover;
    background-position: center;
    margin-right: 13px;
  }

  .img-avatar {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background-size: cover;
    background-position: center;
    margin-right: 13px;
  }

  .img-avatar-not {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background: #5d5d5d;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    margin-right: 13px;
    color: white;
  }

  .notificacion-item {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background: #333;
    }
  }

  .dropdown-menu.show {
    width: 469px !important;
    left: -457px !important;

    background: #121212;
    color: white;
    padding: 10px;

    .title {
      font-size: 20px;
      font-weight: 600;
      border-bottom: 1px solid #eee;
    }
  }
}

.avatar {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.navDashboard {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 60px;
  // box-shadow: 0px 1px 5px 1px #33333326;
  padding-left: 281px;
  padding-right: 28px;
  z-index: 1;

  .dropdown-menu.show {
    padding: 10px;
    left: -88px !important;
    width: 201px;
    background: #121212;
    color: white;

    .dropdown-item {
      padding: 9px;
      border-radius: 9px;
      transition: 0.4s;

      &:hover {
        background: #333;
      }
    }

    .tipo {
      border-bottom: 1px solid;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 10px;
      color: #8d8d8d;
    }
  }

  .menu-nav-dashboard {
    justify-content: center;
    align-items: center;
  }

  .nombre-user {
    font-weight: 600;
    color: #fff;
  }

  .avatar {
    height: 44px;
    width: 44px;
    background: #1e1e1e;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #eee;
    margin-left: 15px;
    box-shadow: none !important;
  }

  .container-nav-dashboard {
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .logo-dashboard {
      width: 115px;
    }
  }
}
</style>
