<template>
  <div class="SidebarTwo">
    <div class="logo-sidebar-two">
      <img style="cursor:pointer" @click="irRuta('ContentHomeTwo')" src="/img/logo/logo-min4.png" alt="" class="img-fluid" />
    </div>

    <div class="menu" style="margin-top: 64px">
      <ul>
        <li class="d-flex flex-row" style="align-items:center; color:white">
          <div style="width: 25%">
            <svg
              style=""
              fill="white"
              role="img"
              height="24"
              width="24"
              aria-hidden="true"
              class="Svg-sc-ytk21e-0 gQUQL home-active-icon"
              viewBox="0 0 24 24"
              data-encore-id="icon"
            >
              <path
                d="M13.5 1.515a3 3 0 0 0-3 0L3 5.845a2 2 0 0 0-1 1.732V21a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6h4v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V7.577a2 2 0 0 0-1-1.732l-7.5-4.33z"
              ></path>
            </svg>
          </div>

          <strong @click="irRuta('ContentHomeTwo')">Home</strong>
        </li>
        <hr style="    border-top: 1px solid rgb(212 212 212 / 10%);">

        
        <div v-if="$store.state.user && $store.state.user.type == 'gamer'">
            <li   @click="irRuta('dashboardGamer')" class="d-flex flex-row mt-4" style="align-items:center; color:white">
          <div style="width: 25%">
            <i class="fas fa-user-astronaut mr-2" style="font-size: 23px;"></i>
          </div>
          <strong>Streaming</strong>
        </li>


        <li @click="irRuta('Games')" class="d-flex flex-row mt-4" style="align-items:center; color:white">
          <div style="width: 25%">
            <i class="fas fa-gamepad mr-2" style="font-size: 23px;"></i>
          </div>

          <strong>Competición</strong>
        </li>

        </div>

        <div v-if="$store.state.user && $store.state.user.type == 'influencer'">
        <li   @click="irRuta('dashboardInfluencer')" class="d-flex flex-row mt-4" style="align-items:center; color:white">
          <div style="width: 25%">
            <i class="fab fa-modx mr-2" style="font-size: 23px;"></i>
          </div>
          <strong>Panel</strong>
        </li>
        </div>

        <div v-if="$store.state.user && $store.state.user.type == 'admin'">
        <li   @click="irRuta('dashboardAdmin')" class="d-flex flex-row mt-4" style="align-items:center; color:white">
          <div style="width: 25%">
            <i class="fab fa-modx mr-2" style="font-size: 23px;"></i>
          </div>
          <strong>Panel</strong>
        </li>
        </div>


      </ul>

      <ul style="position: absolute;
    bottom: 29px;
    color: #636363;
    font-weight: 600;">
    <a href="https://ecuatro.es/politica-de-privacidad.html" target="_blank"><li style="margin-top:10px !important; ">
           Políticas de privacidad 
        </li></a>
        <a href="https://ecuatro.es/terminos-y-condiciones.html" target="_blank"><li style="margin-top:10px !important; ">
           Términos y condiciones 
        </li></a>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    methods: {
        irRuta(ruta){
      this.$router.push({name:ruta})
    }
    }
  }
</script>
<style lang="scss" scoped>
.SidebarTwo {
  position: relative;
  background: #000;
  position: fixed;
  left: 0;
  width: 240px;
  bottom: 0;
  top: 0;
  z-index: 1;
  padding: 43px 25px;
  text-align: left;

  ul {
    a {
      color: #646464;
    }
    li {
        cursor:pointer;
        margin-top: 30px !important;
    }
  }

  .logo-sidebar-two {
    width: 160px;
    text-align: left;
  }
}
</style>
