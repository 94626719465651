import Vue from 'vue'
import VueRouter from 'vue-router'
// Ruutes Base 
import Home from '../Template/Home/index.vue'
import Auth from '../Template/Auth/index.vue';
import Dashboard from '../Template/Dashboard';
import gamerDashboard from '../Template/gamerDashboard';
import homeOpen from '../Template/HomeTwo';
import verificarCuenta from '../Template/gamerDashboard/pageVerificacion';




Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'HomeOpen',
        component: homeOpen,
        children: [{
                path: "/",
                name: "ContentHomeTwo",
                component: () =>
                    import ("../Template/HomeTwo/Content")
            },
            {
                path: "competiciones",
                name: "Competiciones",
                component: () =>
                    import ("../Template/HomeTwo/Competiciones.vue")
            },
            {
                path: "cc",
                name: "CC",
                component: () =>
                    import ("../Template/HomeTwo/CC.vue")
            },
        ]


    },


    {
        path: '/dashboard/adminsitrator',
        name: '',
        component: Dashboard,
        children: [{
                path: "/",
                name: "dashboardAdmin",
                component: () =>
                    import ("../views/admin/Dashboard.vue")
            },
            {
                path: "juegos",
                name: "juegosAdmin",
                component: () =>
                    import ("../views/admin/juegos/index.vue")
            },
            {
                path: "espera/:id",
                name: "listaEspera",
                props: true,
                component: () =>
                    import ("../views/admin/torneos/listaEspera.vue")
            },
            {
                path: "torneos",
                name: "torneosAdmin",
                component: () =>
                    import ("../views/admin/torneos/index.vue")
            },
            {
                path: "gamers",
                name: "listaGamersAdmin",
                component: () =>
                    import ("../views/admin/users/Gamers.vue")
            },
            {
                path: "influencers",
                name: "listaInfluencersAdmin",
                component: () =>
                    import ("../views/admin/users/Influencers.vue")
            },

        ],
        meta: {
            requiresAuth: true,
            roll: 'admin'
        },
    },

    {
        path: '/dashboard/influencer',
        name: '',
        component: Dashboard,
        children: [{
                path: "/",
                name: "dashboardInfluencer",
                component: () =>
                    import ("../views/influencer/Dashboard.vue")
            },
            {
                path: "torneos",
                name: "torneosInfluencer",
                component: () =>
                    import ("../views/influencer/torneos/index.vue")
            },
            {
                path: "cuenta",
                name: "cuentaInfluencer",
                component: () =>
                    import ("../views/influencer/cuenta/index.vue")
            },
            {
                path: "followers",
                name: "seguidoresInfluencer",
                component: () =>
                    import ("../views/influencer/seguidores/index.vue")
            },
            {
                path: "agenda",
                name: "agendaInfluencer",
                component: () =>
                    import ("../views/influencer/agenda/index.vue")
            },
            {
                path: "requests",
                name: "requestsInfluencer",
                component: () =>
                    import ("../views/influencer/solicitudes/index.vue")
            },
            {
                path: "requests-calendar",
                name: "requestsCalendarInfluencer",
                component: () =>
                    import ("../views/influencer/solicitudes/indexCalendar.vue")
            },
            {
                path: "income",
                name: "incomeInfluencer",
                component: () =>
                    import ("../views/influencer/ingreso/index.vue")
            },

        ],
        meta: {
            requiresAuth: true,
            roll: 'influencer'
        },
    },

    {
        path: '/dashboard/gamer',
        name: '',
        component: gamerDashboard,
        children: [{
                path: "/",
                name: "dashboardGamer",
                component: () =>
                    import ("../views/gamer/Influencers.vue")
            },
            {
                path: "games",
                name: "Games",
                component: () =>
                    import ("../views/gamer/games/index.vue")
            },
            {
                path: "championship",
                name: "Championship",
                component: () =>
                    import ("../views/gamer/torneos/index.vue")
            },
            {
                path: "championship/:game/lista",
                name: "ChampionshipLista",
                props: true,
                component: () =>
                    import ("../views/gamer/torneos/usertorneos.vue")
            },

            {
                path: "/championship/:id/torneos/lista",
                name: "ChampionshipListaTorneos",
                props: true,
                component: () =>
                    import ("../views/gamer/torneos/viewTorneo.vue")
            },


            {
                path: "cuenta",
                name: "cuentaGamer",
                component: () =>
                    import ("../views/gamer/cuenta/index.vue")
            },
        ],
        meta: {
            requiresAuth: true,
            roll: 'gamer'
        },
    },

    {
        path: '/verific/token/account/:token',
        name: 'VerificarCuenta',
        component: verificarCuenta,
        props: true
    },

    {
        path: '/:username',
        name: 'Profi',
        props: true,
        component: gamerDashboard,
        children: [{
            path: "",
            name: "perfilUser",
            props: true,
            component: () =>
                import ("../views/home/perfil/index.vue")
        }, ]
    },

    {
        path: '/auth',
        name: '',
        component: Auth,
        children: [{
                path: "login",
                name: "Login",
                component: () =>
                    import ("../Template/Auth/Login")
            },
            {
                path: "register-fan",
                name: "RegisterFan",
                component: () =>
                    import ("../Template/Auth/Register")
            },
            {
                path: "register-creator",
                name: "RegisterCreator",
                component: () =>
                    import ("../Template/Auth/Register")
            },
            {
                path: "register-fan/thank-you",
                name: "AgradecimientoFan",
                component: () =>
                    import ("../Template/Auth/Agradecimiento")
            },
            {
                path: "register-creator/thank-you",
                name: "AgradecimientoCreador",
                component: () =>
                    import ("../Template/Auth/Agradecimiento")
            },







        ],
    },



    {
        path: '/example/bracket',
        name: '',
        component: () =>
            import ("../Example/index.vue")

    },

    {
        path: '/example/calendar_test',
        name: 'calendar_test',
        component: () =>
            import ("../Example/calendar_test.vue")
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


// router.beforeEach((to, from, next) => {
//   console.log(to.matched.some(record => record.meta.requiresAuth));
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//       if (!store.getters.loggedIn) {
//           console.log('requiresAuth');
//           next({ name: 'Login' })
//       } else {
//           console.log('Validacion ruta');
//           var TypeUser = to.matched.some(record => record.meta.roll == store.state.user.type);
//           console.log(store.state.user.type, 'userType');
//           console.log(to.matched.some(record => record.meta.roll == store.state.user.type), 'meta');
//           if (!TypeUser) {
//               if (store.state.user.type == 'admin') {
//                   console.log('Ruta incorrecta');
//                   next({ name: 'HomeManager' })
//               }
//               if (store.state.user.type == 'empresa') {
//                   console.log('Ruta incorrecta');
//                   next({ name: 'Bookings' })
//               }
//           } else {
//               console.log('Ruta correcta');
//               next()
//           }
//       }
//   } else {
//       console.log('NO requiresAuth');
//       next() // make sure to always call next()!
//   }
// })


export default router