var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('slideInDown'),expression:"'slideInDown'"}],staticClass:"navDashboard"},[_c('div',{staticClass:"container-nav-dashboard d-flex flex-row"},[(_vm.$store.state.user)?_c('div',{staticClass:"logo-dashboard d-flex flex-row"},[_c('router-link',{attrs:{"to":_vm.$store.state.user.type == 'gamer'
          ? { name: 'ContentHomeTwo' }
          : _vm.$store.state.user.type == 'admin'
            ? { name: 'dashboardAdmin' }
            : { name: 'dashboardInfluencer' }}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/img/logo/logo-min4.png","alt":""}})])],1):_vm._e(),(_vm.$store.state.user && _vm.$store.state.user.type == 'gamer')?_c('div',{staticClass:"buscador-gamer lg"},[_c('input',{staticStyle:{"display":"none"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.buscarGeneral),expression:"buscarGeneral"}],staticClass:"form-control",staticStyle:{"background":"rgb(217 217 217)"},attrs:{"type":"text","name":"no-buscar","autocomplete":"nope"},domProps:{"value":(_vm.buscarGeneral)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.buscarGlobal()},"input":function($event){if($event.target.composing)return;_vm.buscarGeneral=$event.target.value}}})]):_vm._e(),(_vm.$store.state.user)?_c('div',{staticClass:"menu-nav-dashboard d-flex flex-row"},[_c('div',{staticClass:"dropdown noti",on:{"click":function($event){$event.preventDefault();return _vm.cerrar.apply(null, arguments)}}},[(
          (_vm.$store.state.user && _vm.$store.state.user.type == 'gamer') ||
          _vm.$store.state.user.type == 'influencer'
        )?_c('div',{staticClass:"notificaciones xs",on:{"click":function($event){$event.preventDefault();_vm.$store.state.notificacionesActive = true, _vm.ValidateNoti()}}},[(_vm.$store.state.alertNotificacion)?_c('i',{staticClass:"fas fa-circle",staticStyle:{"position":"absolute","font-size":"7px","color":"#f96c6c","right":"6px"}}):_vm._e(),_c('i',{staticClass:"fas fa-bell",staticStyle:{"color":"rgb(217 217 217)"}},[_c('div',{staticClass:"number-noti",staticStyle:{"position":"absolute","font-size":"15px","top":"0px","right":"0px"}},[_vm._v(" "+_vm._s(_vm.$store.state.countNotificacion)+" ")])])]):_vm._e(),(
            (_vm.$store.state.user && _vm.$store.state.user.type == 'gamer') ||
            _vm.$store.state.user.type == 'influencer'
          )?_c('div',{staticClass:"lg notificaciones",staticStyle:{"margin-right":"95px","cursor":"pointer","width":"39px","font-size":"23px","color":"#fff","position":"relative"},attrs:{"data-toggle":"dropdown","aria-expanded":"false"},on:{"click":function($event){$event.preventDefault();return _vm.ValidateNoti()}}},[(_vm.$store.state.alertNotificacion)?_c('i',{staticClass:"fas fa-circle",staticStyle:{"position":"absolute","font-size":"7px","color":"#f96c6c","right":"6px"}}):_vm._e(),_c('i',{staticClass:"fas fa-bell",staticStyle:{"color":"rgb(217 217 217)"}},[_c('div',{staticClass:"number-noti",staticStyle:{"position":"absolute","font-size":"15px","top":"0px","right":"0px"}},[_vm._v(" "+_vm._s(_vm.$store.state.countNotificacion)+" ")])])]):_vm._e(),_c('div',{staticClass:"dropdown-menu"},[_c('div',{staticClass:"title"},[_vm._v("Notificaciones")]),_c('br'),(_vm.$store.state.notificaciones[0])?_vm._l((_vm.$store.state.notificaciones),function(item,index){return _c('div',{key:index,staticClass:"notificacion-item",staticStyle:{"position":"relative","padding":"10px","border-bottom":"1px solid rgb(57 57 57)"},on:{"click":function($event){item.type == 1
                          ? _vm.irTorneo(item.evento_id)
                          : item.type == 2
                            ? _vm.irProfile(item.evento_id)
                            : null}}},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"avatar-logo-img"},[(item.type == 2)?[(item.user && item.user.avatar)?_c('div',{staticClass:"img-avatar",style:({
                      'background-image':
                        'url(' + _vm.$base.server + item.user.avatar + ')',
                    })}):_vm._e(),(item.user && !item.user.avatar)?_c('div',{staticClass:"img-avatar",style:({
                      'background-image': 'url(/img/iconos/avatar.png)',
                    })}):_vm._e()]:[(item.torneo && item.torneo.juego)?_c('div',{staticClass:"img-torneo",style:({
                      'background-image':
                        'url(' + _vm.$base.server + item.torneo.juego.miniatura + ')',
                    })}):_vm._e()]],2),_c('div',{staticClass:"div"},[_c('div',{staticClass:"title-item",staticStyle:{"font-size":"12px","font-weight":"900"}},[_vm._v(" "+_vm._s(item.titulo)+" ")]),_c('div',{staticClass:"body",staticStyle:{"font-size":"12px"},domProps:{"innerHTML":_vm._s(item.body)}}),_c('small',{staticStyle:{"font-size":"11px","font-weight":"500","color":"#919191","right":"18px","float":"right","margin-top":"0px","margin-bottom":"11px","position":"absolute","top":"0"}},[_vm._v(_vm._s(_vm.moment(item.created_at).fromNow()))])])])])}):[_c('div',{staticClass:"text"},[_vm._v("No tienes notificaciones")])]],2)]),_c('div',{staticClass:"menu-avatar",on:{"click":function($event){$event.preventDefault();return _vm.cerrar.apply(null, arguments)}}},[_c('div',{staticClass:"dropdown"},[_c('div',{staticClass:"d-flex flex-row",staticStyle:{"align-items":"center"},attrs:{"type":"button","data-toggle":"dropdown","aria-expanded":"false"}},[_c('div',{staticClass:"nombre-user lg"},[_vm._v(_vm._s(_vm.$store.state.user.name))]),(!_vm.$store.state.user.avatar)?_c('div',{staticClass:"avatar",style:({
              'background-image': 'url(/img/iconos/avatar.png)',
            })}):_vm._e(),(_vm.$store.state.user.avatar)?_c('div',{staticClass:"avatar d-flex",style:({
              'background-image':
                'url(' + _vm.$base.server + _vm.$store.state.user.avatar + ')',
            })}):_vm._e()]),_c('div',{staticClass:"dropdown-menu"},[_c('div',{staticClass:"tipo"},[_vm._v(" "+_vm._s(_vm.$store.state.user.type == "admin" ? "Administrador" : _vm.$store.state.user.type == "gamer" ? "Fan" : "Creador de contenido")+" ")]),(_vm.$store.state.user.type != 'admin')?[_c('router-link',{attrs:{"to":_vm.$store.state.user.type == 'gamer'
                  ? { name: 'cuentaGamer' }
                  : { name: 'cuentaInfluencer' }}},[_c('a',{staticClass:"dropdown-item",staticStyle:{"color":"#fff"},attrs:{"href":"#"}},[_vm._v("Mi cuenta")])]),_c('hr')]:_vm._e(),_c('a',{staticClass:"dropdown-item",staticStyle:{"color":"#fff"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout()}}},[_vm._v("Cerrar Sesión "),_c('i',{staticClass:"fas fa-sign-out ml-2"})])],2)])])]):_c('div',{staticClass:"NavTwoTo d-flex"},[_c('div',{staticClass:"logo-dashboard lg",staticStyle:{"position":"absolute","left":"141px","margin-top":"13px"}},[_c('router-link',{attrs:{"to":{ name: 'ContentHomeTwo' }}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/img/logo/logo-min4.png","alt":""}})])],1),_c('ul',{staticClass:"d-flex flex-row"},[_c('li',[_c('button',{staticClass:"register",on:{"click":function($event){return _vm.$router.push({ name: 'RegisterFan' })}}},[_vm._v(" Regístrate como Fan ")]),_c('button',{staticClass:"register",on:{"click":function($event){return _vm.$router.push({ name: 'RegisterCreator' })}}},[_vm._v(" Regístrate como Creador de contenido ")])]),_c('li',[_c('button',{staticClass:"login",on:{"click":function($event){return _vm.$router.push({ name: 'Login' })}}},[_vm._v(" Iniciar ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }