<template>
  <div class="Auth">
    <div class="regresar d-flex" @click="regresar()">
      <i class="far fa-chevron-left"></i>
    </div>
    <div
       v-if="$route.name == 'Login' ||  $route.name == 'RegisterFan' ||  $route.name == 'RegisterCreator'"
      class="imagen-login lg"
      style="background-image: url('/img/home/control.jpg')"
      v-animate-css="'fadeIn'"
    ></div>

    <div
       v-if="$route.name == 'AgradecimientoCreador' ||  $route.name == 'AgradecimientoFan'"
      class="imagen-login lg"
      style="background-image: url('/agradecimiento.png')"
      v-animate-css="'fadeIn'"
    ></div>






    <div class="row auth-position">
      <div class="col-9 col-lg-5 d-flex flex-column">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    regresar(){
      if(this.$route.name == 'Login' || this.$route.name == 'RegisterFan' ||  this.$route.name == 'RegisterCreator'){
        this.$router.push({ name: 'ContentHomeTwo' })
      }else {
        this.logout()
      }
      
    },
    logout() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href = "/";
        })
        .catch((error) => {
          toastr.error("Por favor recargue la pagina");
        });
    },
  }
}
</script>
<style lang="scss" scoped>
@media(max-width:768px){

.regresar{
    height: 33px !important;
    width: 33px !important;
  }
  .auth-position{
      justify-content: center !important;
  }
}
  
</style>
<style lang="scss" scoped>

.Auth {
  .regresar {
    position: absolute;
    z-index: 100;
    cursor: pointer;
    background: #727272;
    height: 50px;
    width: 50px;
    
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    top: 35px;
    left: 57px;
    transition: 0.4s;
    &:hover {
      height: 47px;
      width: 47px;
      background: #eee;
      color: rgb(235, 96, 28);
    }
  }

  .logo {
    width: 100px;
    margin-left: 76px;
    transition: 1.6s;
  }

  .imagen-login {
    height: 100vh;
    width: 55%;
    top: 0;
    bottom: 0;
    position: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(0.5);
  }
  background: #121212;
  height: 100%;
  min-height: 100vh;
  color: white;
  background-repeat: no-repeat;
  background-size: contain;

  .auth-position {
    justify-content: right;
    height: 100vh;
    .col-5 {
      padding-right: 65px;
      padding-right: 65px;
      justify-content: center;
    }
  }
}
</style>
