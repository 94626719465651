<template>
  <div class="buscadorGlobal">
    <div
      @click.prevent="cerrarModal()"
      class="cerrar d-flex"
      style="
        position: absolute;
        right: 128px;
        cursor: pointer;
        font-size: 24px;
        border-radius: 50px;
        height: 50px;
        width: 50px;
        border: 1px solid #eee;
        top: 43px;
        justify-content: center;
        align-items: center;
      "
    >
      <i class="fal fa-times"></i>
    </div>
    <div
      class="card-juegos"
      v-if="$store.state.buscarGlobal && $store.state.buscarGlobal.juegos[0]"
    >
      <div class="title-buscador">Juegos</div>
      <div class="row">
        <div
          class="col-3 mb-4"
          v-for="(item, index) in $store.state.buscarGlobal.juegos"
          :key="index"
          v-animate-css="'zoomIn '"
        >
          <div class="card" style="text-align: left" @click.prevent="irRuta(item.id)">
            <div
              class="card-body"
              :style="{ 'background-image': 'url(' + $base.server + item.banner + ')' }"
            >
              <div
                class="cont-icon"
                style="
                  background: transparent;
                  width: fit-content;
                  padding: 3px 10px;
                  border-radius: 9px;
                  position: absolute;
                  right: 9px;
                  top: 7px;
                  justify-content: center;
                  align-items: center;
                "
              >
                <i class="fas fa-gamepad" style="color: #ffffff; font-size: 18px"></i>
              </div>
            </div>
            <div class="card-footer">
              <div class="nombreJuego">
                {{ item.nombre }}
              </div>
              <div class="plataformaJuego">
                {{ item.plataforma }}
              </div>
              <div
                class="cantidadTorneos f-flex flex-row"
                style="position: absolute; bottom: 6px; right: 18px"
              >
                <i class="fas fa-trophy-alt mr-1"></i>
                <strong>{{ calcularTorneos(item) }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="texto-not">
        <div class="title-buscador">Juegos</div>
        Búsqueda no coincide con ningún juego de la plataforma
      </div>
    </div>
    <br />
    <hr />
    <br />

    <div
      class="row lista-cc"
      v-if="$store.state.buscarGlobal && $store.state.buscarGlobal.user[0]"
    >
      <div class="col-12">
        <div class="title-buscador">Creadores de contenido</div>
      </div>

      <div
        class="col-4 mb-3"
        v-for="(item, index) in $store.state.buscarGlobal.user"
        :key="index"
      >
        <div class="card" v-animate-css="'slideInUp'">
          <div
            class="card-body d-flex flex-row"
            style="align-items: center"
            @click="irProfile(item.username)"
          >
            <div
              class="adorno"
              v-animate-css="'zoomIn'"
              style="position: absolute; top: -5px; right: -6px; width: 41px"
            >
              <img src="/img/iconos/tag.png" alt="" class="img-fluid" />
            </div>
            <div @click="irProfile(item.username)" class="vatar-user">
              <div
                class="avatarImage"
                v-if="item.avatar"
                :style="{
                  'background-image': 'url(' + $base.server + item.avatar + ')',
                }"
              ></div>
              <div class="avatarLetra d-flex" v-if="!item.avatar">
                {{ item.username.substr(0, 1) }}
              </div>
            </div>
            <div
              class="contenido-influencer d-flex flex-column"
              style="margin-left: 11px; text-align: left"
            >
              <div @click="irProfile(item.username)" class="nombreUser">
                {{ item.username }}
              </div>
              <div @click="irProfile(item.username)" class="followerUser">
                {{ calcularSeguidores(item) }}
              </div>
              <div @click="irProfile(item.username)" class="iconsRedes">
                <div class="redes d-flex flex-row" style="">
                  <i class="fab fa-instagram mr-1"></i>
                  <i class="fab fa-facebook mr-1"></i>
                  <i class="fab fa-youtube mr-1"></i>
                  <i class="fab fa-twitch mr-1"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="texto-not">
        <div class="title-buscador">Creadores de contenido</div>
        Búsqueda no coincide con ningún creador de contenido de la plataforma
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active_siguir: false,
      user_seguido: 2,
    };
  },
  methods: {
    cerrarModal() {
      this.$store.state.buscarGlobal = null;
      this.$store.state.cerrarModalGlobal = true;
    },
    irProfile(username) {
      this.cerrarModal();
      if (this.$route.name == "perfilUser") {
        location.href = "/" + username;
      } else {
        this.$router.push({ path: "/" + username });
      }
    },
    calcularSeguidores(item) {
      var cuentaSeguidores = item.seguidores.length;
      if (cuentaSeguidores > 0) {
        if (cuentaSeguidores == 1) {
          return cuentaSeguidores + " Seguidor";
        } else {
          return cuentaSeguidores + " Seguidores";
        }
      } else {
        return "No tiene seguidores";
      }
    },

    verificarSeguidor(item) {
      var _vm = this;
      var verificado = item.seguidores.filter(function (arr) {
        return arr.seguidor_id == _vm.$store.state.user.id;
      })[0];
      if (verificado) {
        return true;
      } else {
        return false;
      }
    },

    DejarSeguir(seguido) {
      this.active_siguir = true;
      this.user_seguido = seguido;

      let url = this.$base.dominio + "/gamer/dejarseguir";
      const datos = {
        seguidor_id: this.$store.state.user.id,
        seguido_id: seguido,
      };

      axios
        .post(url, datos)
        .then((res) => {
          this.active_siguir = false;
          this.user_seguido = null;
          this.$parent.Listar();
        })
        .catch((e) => {
          this.active_siguir = false;
          this.user_seguido = null;
          this.$parent.Listar();
          console.log(e);
        });
    },

    Seguir(seguido) {
      this.active_siguir = true;
      this.user_seguido = seguido;

      let url = this.$base.dominio + "/gamer/seguir";
      const datos = {
        seguidor_id: this.$store.state.user.id,
        seguido_id: seguido,
      };

      axios
        .post(url, datos)
        .then((res) => {
          this.active_siguir = false;
          this.user_seguido = null;
          this.$parent.Listar();
        })
        .catch((e) => {
          this.active_siguir = false;
          this.user_seguido = null;
          this.$parent.Listar();
          console.log(e);
        });
    },

    irRuta(juego) {
      this.cerrarModal();
      if (this.$route.name == "ChampionshipLista") {
        location.href = "/dashboard/gamer/championship/" + juego + "/lista";
      } else {
        this.$router.push({ path: "/dashboard/gamer/championship/" + juego + "/lista" });
      }
    },
    calcularTorneos(juego) {
      return juego.torneos.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.lista-cc {
  .card {
    border: none !important;

    .seguirButton {
      border: 1px solid #fff;
      padding: 0px 4px;
      border-radius: 9px;
      position: absolute;
      right: 16px;
      font-size: 12px;
      bottom: 10px;
      transition: 0.4s;
      cursor: pointer;
      color: white;
      padding-left: 10px;
      padding-right: 10px;
      &:hover {
        background: white;
        color: #2c2c2c;
      }
    }

    .card-body {
      align-items: center;
      cursor: pointer;
      min-height: 113px;
      align-items: center;
      background: #2c2c2c;
      color: white;
      border-radius: 17px;
      box-shadow: 0px 0px 8px 4px #0000003d;

      .avatarLetra {
        transition: 0.3s;
        height: 60px;
        font-weight: 500;
        width: 60px;
        background: white;
        color: #333;
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
      }
      .avatarImage {
        transition: 0.3s;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        font-size: 1rem;
        display: inline-flex;
        width: 60px;
        height: 60px;
        color: #fff;
        border-radius: 50%;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 2px 1px #d7d7d7;
      }

      &:hover {
        .avatarImage {
          width: 51px;
          height: 51px;
        }
        .avatarLetra {
          width: 51px;
          height: 51px;
          color: #673ab7;
        }
      }
    }
  }
  .nombreUser {
    font-weight: 600;
  }
  .followerUser {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
.buscadorGlobal {
  overflow: scroll;
  position: fixed;
  z-index: 10;
  left: 0;
  background: white;
  bottom: 0;
  right: 0;
  top: 60px;
  padding: 120px;
  text-align: left;
  .title-buscador {
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 13px;
  }

  .card-juegos {
    .card {
      transition: 0.4s;
      // box-shadow: 0px 0px 40px 2px #0000003d;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
      &:hover {
        transform: scale(1.1);
      }
      &:focus {
        transform: scale(1.1);
      }

      .card-footer {
        padding: 7px;
      }
      border: none !important;

      .nombreJuego {
        font-weight: 700;
        text-transform: uppercase;
      }
      .plataformaJuego {
        font-size: 11px;
        margin-top: 3px;
        margin-bottom: 5px;
        font-weight: 900;
        text-transform: uppercase;
        color: #7b7b7b;
      }

      .card-body {
        height: 199px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 5px 5px 0px 0px;
        align-items: center;
        cursor: pointer;
        min-height: 113px;
        align-items: center;
        background-color: #151515;
        color: white;

        .avatarLetra {
          transition: 0.3s;
          height: 60px;
          font-weight: 500;
          width: 60px;
          background: white;
          color: #333;
          font-size: 20px;
          text-transform: uppercase;
          text-align: center;
          border-radius: 50px;
          justify-content: center;
          align-items: center;
        }
        .avatarImage {
          transition: 0.3s;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          font-size: 1rem;
          display: inline-flex;
          width: 60px;
          height: 60px;
          color: #fff;
          border-radius: 50%;
          background-color: #fff;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 0px 2px 1px #d7d7d7;
        }

        &:hover {
          .avatarImage {
            width: 51px;
            height: 51px;
          }
          .avatarLetra {
            width: 51px;
            height: 51px;
            color: #673ab7;
          }
        }

        .seguirButton {
          border: 1px solid #fff;
          padding: 0px 4px;
          border-radius: 9px;
          position: absolute;
          right: 16px;
          font-size: 12px;
          bottom: 10px;
          transition: 0.4s;
          cursor: pointer;
          &:hover {
            background: white;
            color: #673ab7;
          }
        }
      }
    }
    .nombreUser {
      font-weight: 600;
    }
    .followerUser {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
}
</style>
