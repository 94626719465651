<template>
   
        <div class="pantallaBloqueo">
          <div
            class="nav d-flex"
            style="
              height: 60px;
              border-bottom: 1px solid rgb(238, 238, 238);
              align-items: center;
              padding: 0px 197px;
              justify-content: space-between;
            "
          >
            <div class="log">
              <img
                src="/img/logo/logo-negro.png"
                alt=""
                class="img-fluid"
                style="width: 122px"
              />
            </div>

            <div class="title-label">
              <i class="fab fa-modx mr-1"></i>
              <strong> </strong>
            </div>
           
          </div>

          <div class="centro d-flex flex-column">
            <img
              src="/img/home/confirmacion-astro.png"
              alt=""
              style="width: 252px; margin-bottom: 21px"
            />
            <div class="title-pantall" style="font-size: 42px; text-transform: uppercase">
              {{mensaje}}
            </div>
            <div class="small" style="font-size: 19px">
             <a href="#" @click="IrHome()">Regresar al Home</a>
            </div>

            <br />
          </div>
        </div>

</template>

<script>

import SocketioService from "../../services/socketio.service";

   export default {
    props:['token'],
    data(){
       return {
        mensaje:'Verificando cuenta ... '
       }
    },
    mounted(){
        var _vm = this;
        setTimeout(() => {
            _vm.verificar(_vm.token);
        }, 600)
       
    }, 
    methods:{
        verificar(token){
           let url = this.$base.dominio + '/gamer/confirmation/token';
           const datos = {
            token: token
           }
           axios.post(url, datos).then(res => {
              if(res.data.state == true){
                 this.mensaje = 'Cuenta verificada con éxito';
                 const items = {
                    user_id:res.data.data.id
                  }
                  var noti = SocketioService.socket.emit("vf-user", items);

              }else{
                this.mensaje = 'Token no existe o ya expiro';
              }
           })
        },
        IrHome(){
            this.$router.push({name: 'Home'})
        }
    }
   }
</script>

<style lang="scss" scoped>
.nav {
  .nombre-user {
    font-weight: 600;
    color: #2a2a2a;
  }
  .avatar {
    height: 44px;
    width: 44px;
    background: #673ab7;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #eee;
    margin-left: 15px;
  }
}

.pantallaBloqueo {
  background: white;
  position: fixed;
  left: 0;
  z-index: 10000;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
}

.centro {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
 
</style>