<template>
  <div class="Sidebar">
    <div class="menu-header">
      <div class="logo" v-animate-css="'slideInDown'">
        <a href='https://play.ecuatro.es/dashboard/adminsitrator/'><img style="cursor:pointer" src="/img/logo/logo-min4.png" alt="" class="img-fluid" /></a> 
      </div>
    </div>
    <div class="menu-sidebar">
      <!-- Menu Admin  -->
      <ul v-if="$store.state.user.type == 'admin'">
        <li class="">
          <small style="margin-left: 27px; font-weight: bold;     color: #676767;">HOME</small>
          <div class="item-menu d-flex flex-row" style="margin-top: 10px"  @click="cambiarRuta('dashboardAdmin')">
            <i class="fab fa-modx"></i> <span>Panel </span>
          </div>
        </li>

        <br />
        <br />
        <!-- label  -->

        <li
          class=""
          data-toggle="collapse"
          href="#collapseExample"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <small style="margin-left: 27px; font-weight: bold;     color: #676767;">MENU</small>
          <div class="item-menu d-flex flex-row" style="margin-top: 10px">
            <i class="fas fa-users"></i> <span>Usuarios</span>
            <i
              class="fal fa-chevron-right"
              style="font-size: 10px; position: absolute; right: 0px; margin-top: 5px"
            ></i>
          </div>
        </li>
        <div class="collapse" id="collapseExample">
          <ul style="margin-top: 15px; padding-left: 10px">
            <li class="" @click="cambiarRuta('listaInfluencersAdmin')">
              <div
                class="item-menu d-flex flex-row"
                style="margin-top: 10px; font-size: 14px"
              >
                <i class="fas fa-caret-right"></i> <span>Creador de contenido</span>
              </div>
            </li>
            <li class="" @click="cambiarRuta('listaGamersAdmin')">
              <div
                class="item-menu d-flex flex-row"
                style="margin-top: 10px; font-size: 14px"
              >
                <i class="fas fa-caret-right"></i> <span>Fan</span>
              </div>
            </li>
          </ul>
        </div>

        <li class="" @click="cambiarRuta('juegosAdmin')">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-gamepad"></i> <span>Juegos</span>
          </div>
        </li>
        <li class="">
          <div class="item-menu d-flex flex-row" @click="cambiarRuta('torneosAdmin')">
            <i class="fas fa-trophy-alt"></i> <span>Torneos</span>
          </div>
        </li>
        <!-- <li class="">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-trophy-alt"></i> <span>Noticias</span>
          </div>
        </li> -->
      </ul>
      <!-- end menu admin  -->
      <ul v-if="$store.state.user.type == 'influencer'">
        <li class="">
          <small style="margin-left: 27px; font-weight: bold;     color: #676767;">HOME</small>
          <div class="item-menu d-flex flex-row" style="margin-top: 10px"  @click="cambiarRuta('dashboardInfluencer')">
            <i class="fab fa-modx"></i> <span>Panel </span>
          </div>
        </li>

        <br />
        <br />
        <!-- label  -->

        <li class="" @click="cambiarRuta('torneosInfluencer')">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-trophy-alt"></i> <span>Torneos</span>
          </div>
        </li>
        <li class="" @click="cambiarRuta('seguidoresInfluencer')">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-user-plus"></i> <span>Seguidores</span>
          </div>
        </li>
        <li class="" @click="cambiarRuta('agendaInfluencer')">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-calendar-alt"></i> <span>Agenda</span>
          </div>
        </li>
        <li class="" @click="cambiarRuta('requestsInfluencer')">
          <div class="item-menu d-flex flex-row">
           
            <i class="fas fa-clipboard-list"></i> <span>Reservas</span>
          </div>
        </li>
        <li class="" @click="cambiarRuta('requestsCalendarInfluencer')">
          <div class="item-menu d-flex flex-row">
           
            <i class="fas fa-clipboard-list"></i> <span>Reservas Calendario</span>
          </div>
        </li>
        <li class="" @click="cambiarRuta('incomeInfluencer')">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-sack-dollar"></i>
             <span>Ingresos</span>
             
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  
  methods: {
    cambiarRuta(ruta) {
      if (this.$route.name == ruta) {
      } else {
        this.$router.push({ name: ruta });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Sidebar {
  width: 256px;
  text-align: left;
  padding: 8px;
  position: fixed;
  left: 0;
  background: #000;
  top: 0;
  bottom: 0;
  z-index: 2;

  .menu-header {
    padding-left: 27px;
    padding-top: 9px;
  }

  ul {
    margin-top: 104px;
    .item-menu {
      padding-left: 30px;
      transition: 0.5s;
      cursor: pointer;
      margin-top: 35px;
      color: white;
      font-size: 15px;
      align-items: center;
      i {
        width: 20%;
      }

      &:hover {
        padding-left: 30px;
        border-radius: 10px;
        color: #4d93ce;
        font-weight: 600;
        i {
          color: #4d93ce;
        }
      }
    }
  }

  .logo {
    width: 124px;
  }
}
</style>
