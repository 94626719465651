<template>
  <div
    class="NotificacionesM"
    v-animate-css="'slideInUp'"
    v-if="$store.state.notificacionesActive"
  >
    <div class="boton-cerrar" @click.prevent="$store.state.notificacionesActive = false">
      <i class="fal fa-times"></i>
    </div>

    <br />

    <div class="menu-notificacionesM" style="padding: 18px; color: white">
      <div class="title" style="font-size: 18px; font-weight: 600">Notificaciones</div>
      <br />
      <template v-if="$store.state.notificaciones[0]">
        <div
          class="notificacion-item"
          style="
            position: relative;
            padding: 10px;
            border-bottom: 1px solid rgb(46 46 46);
          "
          v-for="(item, index) in $store.state.notificaciones"
          :key="index"
          @click="
            item.type == 1
              ? irTorneo(item.evento_id)
              : item.type == 2
              ? irProfile(item.evento_id)
              : null
          "
        >
          <div class="d-flex flex-row">
            <div class="avatar-logo-img">
              <template v-if="item.type == 2">
                <div
                  class="img-avatar"
                  v-if="item.user && item.user.avatar"
                  :style="{
                    'background-image': 'url(' + $base.server + item.user.avatar + ')',
                  }"
                ></div>
                <div class="img-avatar" v-if="item.user && !item.user.avatar" :style="{
                          'background-image': 'url(/img/iconos/avatar.png)',
                        }">
                 
                </div>
              </template>
              <template v-else>
                <div
                  class="img-torneo"
                  v-if="item.torneo && item.torneo.juego"
                  :style="{
                    'background-image':
                      'url(' + $base.server + item.torneo.juego.miniatura + ')',
                  }"
                ></div>
              </template>
            </div>
            <div class="div">
              <div class="title-item" style="font-size: 12px; font-weight: 900">
                {{ item.titulo }}
              </div>
              <div class="body" style="font-size: 12px" v-html="item.body"></div>

              <small
                style="
                  font-size: 11px;
                  font-weight: 500;
                  color: #919191;
                  right: 0;
                  float: right;
                  margin-top: 0px;
                  margin-bottom: 11px;
                  position: absolute;
                  right: 18px;
                  top: 0;
                "
                >{{ moment(item.created_at).fromNow() }}</small
              >
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text">No tienes notificaciones</div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    irTorneo(torneo) {
      if (this.$route.name == "ChampionshipListaTorneos") {
        location.href = "/championship/" + torneo + "/torneos/lista";
      } else {
        this.$router.push({ path: "/championship/" + torneo + "/torneos/lista" });
      }
    },

    irProfile(username) {
      if (this.$route.name == "perfilUser") {
        location.href = "/" + username;
      } else {
        this.$router.push({ path: "/" + username });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .NotificacionesM {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding-top: 56px;
    bottom: 0;
    background: #080808;
    z-index: 100;
    text-align: left;

    .img-torneo {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      background-size: cover;
      background-position: center;
      margin-right: 13px;
    }
    .img-avatar {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      background-size: cover;
      background-position: center;
      margin-right: 13px;
    }
    .img-avatar-not {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      background: #5d5d5d;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 700;
      margin-right: 13px;
      color: white;
    }

    .notificacion-item {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background: #333;
      }
    }
    .dropdown-menu.show {
      width: 469px !important;
      left: -457px !important;

      background: #121212;
      color: white;
      padding: 10px;

      .title {
        font-size: 20px;
        font-weight: 600;
        border-bottom: 1px solid #eee;
      }
    }

    .boton-cerrar {
      position: absolute;
      right: 38px;
      top: 21px;
      color: white;
      font-size: 31px;
    }
  }
}
</style>
