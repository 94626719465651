<template>
  <div class="content-tow">
    <div class="Juegos-menu" v-if="$store.state.user && $store.state.user.type == 'gamer'">
      <div class="title-menu" v-if="inscripciones">Mis competiciones</div>
      <div class="listado mt-4" v-if="inscripciones">
        <div class="row">
          <div
            class="col-12 col-lg-3 mb-4"
            v-for="(item, index) in inscripciones"
            :key="index"
            v-animate-css="'zoomIn '"
          >
            <div  v-if="item.torneoactive && item.torneoactive.juego" class="card" style="text-align: left" @click.prevent="irTorneo(item.torneoactive.id)">
              <div
                 v-if="item.torneoactive && item.torneoactive.juego"
                class="card-body"
                :style="{ 'background-image': 'url(' + $base.server + item.torneoactive.juego.miniatura + ')' }"
              >
                <div
                  class="cont-icon"
                  style="
                    background: transparent;
                    width: fit-content;
                    padding: 3px 10px;
                    border-radius: 9px;
                    position: absolute;
                    right: 9px;
                    top: 7px;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <i class="fas fa-gamepad" style="color: #ffffff; font-size: 18px"></i>
                </div>
              </div>
              <div class="card-footer"   v-if="item.torneoactive && item.torneoactive.juego">
                <div class="nombreJuego">
                  {{ item.torneoactive.nombre }}
                </div>
                <div class="plataformaJuego">
                  {{ item.torneoactive.juego.plataforma }}
                </div>
                <div
                  class="cantidadTorneos f-flex flex-row"
                  style="position: absolute; bottom: 6px; right: 18px"
                >
                  <i class="fas fa-trophy-alt mr-1"></i>
                  <!-- <strong>{{ calcularTorneos(item) }}</strong> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="inscripciones.length > 3">
          <div class="col-12" style="text-align: right; color: #eee">
            <router-link :to="{name: 'MyCompeticiones'}"><strong  style="cursor:pointer">Mostrar Todo</strong></router-link>
            
          </div>
        </div>
      </div>
      <!-- <div v-else class="not-listado">No te has registrado en una competición</div>
      <br><br><br> -->
    </div>


    <div class="Juegos-menu">
      <div class="title-menu">Juegos de la plataforma</div>
      <div class="listado mt-4" v-if="juegos">
        <div class="row">
          <div
            class="col-12 col-lg-3 mb-4"
            v-for="(item, index) in juegos"
            :key="index"
            v-animate-css="'zoomIn '"
          >
            <div class="card" style="text-align: left" @click.prevent="irRuta(item.id)">
              <div
                class="card-body"
                :style="{ 'background-image': 'url(' + $base.server + item.miniatura + ')' }"
              >
                <div
                  class="cont-icon"
                  style="
                    background: transparent;
                    width: fit-content;
                    padding: 3px 10px;
                    border-radius: 9px;
                    position: absolute;
                    right: 9px;
                    top: 7px;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <i class="fas fa-gamepad" style="color: #ffffff; font-size: 18px"></i>
                </div>
              </div>
              <div class="card-footer">
                <div class="nombreJuego">
                  {{ item.nombre }}
                </div>
                <div class="plataformaJuego">
                  {{ item.plataforma }}
                </div>
                <div
                  class="cantidadTorneos f-flex flex-row"
                  style="position: absolute; bottom: 6px; right: 18px"
                >
                  <i class="fas fa-trophy-alt mr-1"></i>
                  <strong>{{ calcularTorneos(item) }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12" style="text-align: right; color: #eee">
            <router-link :to="{name: 'Competiciones'}"><strong  style="cursor:pointer">Mostrar Todo</strong></router-link>
            
          </div>
        </div>
      </div>
      <div v-else class="not-listado">No tiene juegos registrados</div>
    </div>

    <br />
    <hr />
    <br />

    <div class="lista-cc pb-3">
      <div class="title-menu">Creadores de contenido</div>
      <div class="listado  mt-4" v-if="users">
        <div class="row lista-cc">
          <div class="col-12 col-lg-3 mb-3 mb-2" v-for="(item, index) in users" :key="index">
            <div class="card" v-animate-css="'slideInUp'">
              <div
                class="card-body d-flex flex-row"
                style="align-items: center"
                @click="irProfile(item.username)"
              >
                <div
                  class="adorno"
                  v-animate-css="'zoomIn'"
                  style="position: absolute; top: -5px; right: -6px; width: 41px"
                >
                  <img src="/img/iconos/tag.png" alt="" class="img-fluid" />
                </div>
                <div @click="irProfile(item.username)" class="vatar-user">
                  <div
                    class="avatarImage"
                    v-if="item.avatar"
                    :style="{
                      'background-image': 'url(' + $base.server + item.avatar + ')',
                    }"
                  ></div>
                  <div class="avatarImage d-flex" v-if="!item.avatar"  :style="{
                          'background-image': 'url(/img/iconos/avatar.png)',
                        }">
                  
                  </div>
                </div>
                <div
                  class="contenido-influencer d-flex flex-column"
                  style="margin-left: 11px; text-align: left"
                >
                  <div @click="irProfile(item.username)" class="nombreUser">
                    {{ item.username }}
                  </div>
                  <div @click="irProfile(item.username)" class="followerUser">
                    {{ calcularSeguidores(item) }}
                  </div>
                  <div @click="irProfile(item.username)" class="iconsRedes">
                    <div class="redes d-flex flex-row" style="">
                      <i class="fab fa-instagram mr-1"></i>
                      <i class="fab fa-facebook mr-1"></i>
                      <i class="fab fa-youtube mr-1"></i>
                      <i class="fab fa-twitch mr-1"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12" style="text-align: right; color: #eee">
            <router-link :to="{name: 'CC'}"><strong style="cursor:pointer" >Mostrar Todo</strong></router-link>
            
          </div>
        </div>
      </div>
      <div v-else class="not-listado">No tiene Creadores de contenido</div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.listarJuegos();
    this.listarCC();

    if(this.$store.state.user && this.$store.state.user.type == 'gamer'){
       this.listarIncripciones();
    }
  },
  data() {
    return {
      juegos: null,
      users:null,
      inscripciones:null,

    };
  },
  methods: {
    listarIncripciones(){
      let url = this.$base.dominio + '/gamer/mis/competenciastwo';
      const datos = {
        user_id:this.$store.state.user.id
      }
      axios.post(url, datos).then(res => {
       this.inscripciones = res.data;
      })
    },
     irProfile(username){
    this.$router.push({path: '/'+username});
    },
    calcularSeguidores(item){
      var cuentaSeguidores = item.seguidores.length;
      if(cuentaSeguidores > 0){
           if(cuentaSeguidores == 1){
            return cuentaSeguidores + ' Seguidor'
           }else {
            return cuentaSeguidores + ' Seguidores'
           }
      }else {
        return 'No tiene seguidores'
      }
    },

    listarCC(){

        let url = this.$base.dominio + "/lista/influencers/hometwo";
      axios.post(url).then((res) => {
        this.users = res.data;
      });
    },
    irTorneo(id){
      
      this.$router.push({path:'championship/'+id+'/torneos/lista'})
    },
    irRuta(juego) {
      this.$router.push({ path: "/dashboard/gamer/championship/" + juego + "/lista" });
    },
    calcularTorneos(juego) {
      return juego.torneos.length;
    },
    listarJuegos() {
      let url = this.$base.dominio + "/lista/juegos/hometwo";
      axios.post(url).then((res) => {
        this.juegos = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  @media(max-width:768px){
  .content-tow {
  text-align: left !important;
  padding-top: 80px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-bottom: 50px;
  .title-menu {
    color:white !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }
  }
 }
</style>
<style lang="scss" scoped>
.content-tow {
  text-align: left;
  padding-top: 80px;
  padding-left: 280px;
  padding-right: 54px;

  --background-color: #ff5722;
  background-image: -webkit-linear-gradient(300deg,var(--background-color) 20px,transparent 220px);
    background-image: linear-gradient(150deg,var(--background-color) 20px,transparent 220px);
    width: 100%;

  .lista-cc {
  .card {
    border: none !important;

    .seguirButton {
      border: 1px solid #fff;
      padding: 0px 4px;
      border-radius: 9px;
      position: absolute;
      right: 16px;
      font-size: 12px;
      bottom: 10px;
      transition: 0.4s;
      cursor: pointer;
      color: white;
      padding-left: 10px;
      padding-right: 10px;
      &:hover {
        background: white;
        color: #2c2c2c;
      }
    }

    .card-body {
      align-items: center;
      cursor: pointer;
      min-height: 113px;
      align-items: center;
      background: #2c2c2c;
      color: white;
      border-radius: 17px;
      box-shadow: 0px 0px 8px 4px #0000003d;

      .avatarLetra {
        transition: 0.3s;
        height: 60px;
        font-weight: 500;
        width: 60px;
        background: white;
        color: #333;
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
      }
      .avatarImage {
        transition: 0.3s;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        font-size: 1rem;
        display: inline-flex;
        width: 60px;
        height: 60px;
        color: #fff;
        border-radius: 50%;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 2px 1px #d7d7d7;
      }

      &:hover {
        .avatarImage {
          width: 51px;
          height: 51px;
        }
        .avatarLetra {
          width: 51px;
          height: 51px;
          color: #673ab7;
        }
      }
    }
  }
  .nombreUser {
    font-weight: 600;
  }
  .followerUser {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

  .Juegos-menu {
    .card {
      cursor: pointer;
      transition: 0.4s;
      // box-shadow: 0px 0px 40px 2px #0000003d;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
      &:hover {
        margin-top: -5px;
      }
      &:focus {
        margin-top: -5px;
      }

      .card-footer {
        padding: 7px;
        background: #333;
        color: white;
      }
      border: none !important;

      .nombreJuego {
        font-weight: 700;
        text-transform: uppercase;
      }
      .plataformaJuego {
        font-size: 11px;
        margin-top: 3px;
        margin-bottom: 5px;
        font-weight: 900;
        text-transform: uppercase;
        color: #7b7b7b;
      }

      .card-body {
        height: 199px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 5px 5px 0px 0px;
        align-items: center;
        cursor: pointer;
        min-height: 113px;
        align-items: center;
        background-color: #151515;
        color: white;

        .avatarLetra {
          transition: 0.3s;
          height: 60px;
          font-weight: 500;
          width: 60px;
          background: white;
          color: #333;
          font-size: 20px;
          text-transform: uppercase;
          text-align: center;
          border-radius: 50px;
          justify-content: center;
          align-items: center;
        }
        .avatarImage {
          transition: 0.3s;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          font-size: 1rem;
          display: inline-flex;
          width: 60px;
          height: 60px;
          color: #fff;
          border-radius: 50%;
          background-color: #fff;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 0px 2px 1px #d7d7d7;
        }

        &:hover {
          .avatarImage {
            width: 51px;
            height: 51px;
          }
          .avatarLetra {
            width: 51px;
            height: 51px;
            color: #673ab7;
          }
        }

        .seguirButton {
          border: 1px solid #fff;
          padding: 0px 4px;
          border-radius: 9px;
          position: absolute;
          right: 16px;
          font-size: 12px;
          bottom: 10px;
          transition: 0.4s;
          cursor: pointer;
          &:hover {
            background: white;
            color: #673ab7;
          }
        }
      }
    }
    .nombreUser {
      font-weight: 600;
    }
    .followerUser {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  .title-menu {
    font-size: 24px;
    font-weight: 700;
    color: white;
  }
}
</style>
