var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.state.notificacionesActive)?_c('div',{directives:[{name:"animate-css",rawName:"v-animate-css",value:('slideInUp'),expression:"'slideInUp'"}],staticClass:"NotificacionesM"},[_c('div',{staticClass:"boton-cerrar",on:{"click":function($event){$event.preventDefault();_vm.$store.state.notificacionesActive = false}}},[_c('i',{staticClass:"fal fa-times"})]),_c('br'),_c('div',{staticClass:"menu-notificacionesM",staticStyle:{"padding":"18px","color":"white"}},[_c('div',{staticClass:"title",staticStyle:{"font-size":"18px","font-weight":"600"}},[_vm._v("Notificaciones")]),_c('br'),(_vm.$store.state.notificaciones[0])?_vm._l((_vm.$store.state.notificaciones),function(item,index){return _c('div',{key:index,staticClass:"notificacion-item",staticStyle:{"position":"relative","padding":"10px","border-bottom":"1px solid rgb(46 46 46)"},on:{"click":function($event){item.type == 1
            ? _vm.irTorneo(item.evento_id)
            : item.type == 2
            ? _vm.irProfile(item.evento_id)
            : null}}},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"avatar-logo-img"},[(item.type == 2)?[(item.user && item.user.avatar)?_c('div',{staticClass:"img-avatar",style:({
                  'background-image': 'url(' + _vm.$base.server + item.avatar + ')',
                })}):_vm._e(),(item.user && !item.user.avatar)?_c('div',{staticClass:"img-avatar-not d-flex"},[_vm._v(" "+_vm._s(item.user.name.substr(0, 1))+" ")]):_vm._e()]:[(item.torneo && item.torneo.juego)?_c('div',{staticClass:"img-torneo",style:({
                  'background-image':
                    'url(' + _vm.$base.server + item.torneo.juego.miniatura + ')',
                })}):_vm._e()]],2),_c('div',{staticClass:"div"},[_c('div',{staticClass:"title-item",staticStyle:{"font-size":"12px","font-weight":"900"}},[_vm._v(" "+_vm._s(item.titulo)+" ")]),_c('div',{staticClass:"body",staticStyle:{"font-size":"12px"},domProps:{"innerHTML":_vm._s(item.body)}}),_c('small',{staticStyle:{"font-size":"11px","font-weight":"500","color":"#919191","right":"18px","float":"right","margin-top":"0px","margin-bottom":"11px","position":"absolute","top":"0"}},[_vm._v(_vm._s(_vm.moment(item.created_at).fromNow()))])])])])}):[_c('div',{staticClass:"text"},[_vm._v("No tienes notificaciones")])]],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }