<template>
  <div class="NavTwo d-flex">
    <div class="logoOnly xs">
      <img src="/img/logo/logoOnly.png" alt="" class="img-fluid" />
    </div>


    <div v-if="$store.state.user" class="navTowAvatar d-flex flex-row" style="align-items: center">
      <div class="dropdown noti2">
        <div class="lg notificaciones" data-toggle="dropdown" aria-expanded="false" @click.prevent="ValidateNoti()" v-if="
          ($store.state.user && $store.state.user.type == 'gamer') ||
          $store.state.user.type == 'influencer'
        " style="
                  margin-right: 95px;
                  cursor: pointer;
                  width: 39px;
                  font-size: 23px;
                  color: #fff;
                  position: relative;
                ">
          <i v-if="$store.state.alertNotificacion" class="fas fa-circle"
            style="position: absolute; font-size: 7px; color: #f96c6c; right: 6px"></i>
            <i class="fas fa-bell">
            <div
              class="number-noti"
              style="position: absolute; font-size: 15px; top: 0px; right: 0px"
            >
              {{ $store.state.countNotificacion }}
            </div>
          </i>
        </div>

        <div class="dropdown-menu">
          <div class="title">Notificaciones</div>
          <br />
          <template v-if="$store.state.notificaciones[0]">
            <div class="notificacion-item" style="
                      position: relative;
                      padding: 10px;
                      border-bottom: 1px solid rgb(57 57 57);
                    " v-for="(item, index) in $store.state.notificaciones" :key="index" @click="
                      item.type == 1
                        ? irTorneo(item.evento_id)
                        : item.type == 2
                          ? irProfile(item.evento_id)
                          : null
                    ">
              <div class="d-flex flex-row">
                <div class="avatar-logo-img">
                  <template v-if="item.type == 2">
                    <div class="img-avatar" v-if="item.user && item.user.avatar" :style="{
                      'background-image':
                        'url(' + $base.server + item.user.avatar + ')',
                    }"></div>
                    <div class="img-avatar" v-if="item.user && !item.user.avatar" :style="{
                      'background-image': 'url(/img/iconos/avatar.png)',
                    }"></div>
                  </template>
                  <template v-else>
                    <div class="img-torneo" v-if="item.torneo && item.torneo.juego" :style="{
                      'background-image':
                        'url(' + $base.server + item.torneo.juego.miniatura + ')',
                    }"></div>
                  </template>
                </div>
                <div class="div">
                  <div class="title-item" style="font-size: 12px; font-weight: 900">
                    {{ item.titulo }}
                  </div>
                  <div class="body" style="font-size: 12px" v-html="item.body"></div>

                  <small style="
                            font-size: 11px;
                            font-weight: 500;
                            color: #919191;
                            right: 0;
                            float: right;
                            margin-top: 0px;
                            margin-bottom: 11px;
                            position: absolute;
                            right: 18px;
                            top: 0;
                          ">{{ moment(item.created_at).fromNow() }}</small>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text">No tienes notificaciones</div>
          </template>
        </div>
      </div>

      <div class="nombre-user">{{ $store.state.user.name }}</div>
      <div class="menu-avatar" @click.prevent="cerrar">
        <div class="dropdown">
          <div class="" type="button" data-toggle="dropdown" aria-expanded="false">
            <div class="avatar d-flex" v-if="!$store.state.user.avatar" :style="{
              'background-image': 'url(/img/iconos/avatar.png)',
            }"></div>
            <div class="avatar d-flex" style="" v-if="$store.state.user.avatar" :style="{
              'background-image':
                'url(' + $base.server + $store.state.user.avatar + ')',
            }"></div>
          </div>
          <div class="dropdown-menu">
            <div class="tipo">
              {{
                $store.state.user.type == "admin"
                ? "Administrador"
                : $store.state.user.type == "gamer"
                  ? "Fan"
                  : "Creador de contenido"
              }}
            </div>
            <template v-if="$store.state.user.type != 'admin'">
              <router-link :to="
                $store.state.user.type == 'gamer'
                  ? { name: 'cuentaGamer' }
                  : { name: 'cuentaInfluencer' }
              ">
                <a style="color: #fff" class="dropdown-item" href="#">Mi cuenta</a>
              </router-link>
              <hr />
            </template>
            <a style="color: #fff" class="dropdown-item" href="#" @click.prevent="logout()">Cerrar Sesión <i
                class="fas fa-sign-out ml-2"></i></a>
          </div>
        </div>
      </div>
    </div>
    <ul v-else class="d-flex flex-row">
      <li class="lg">
        <button class="register" @click="$router.push({ name: 'RegisterFan' })">
          Regístrate como Fan 
        </button>
       
         <button class="register" @click="$router.push({ name: 'RegisterCreator' })">
          Regístrate como Creador de contenido
        </button>

      </li>
      <li>
        <button class="login" @click="$router.push({ name: 'Login' })">Iniciar</button>
      </li>
    </ul>

    <div class="menuSidebar xs" @click.prevent="$store.state.sidebarActive = true">
      <svg role="img" height="24" width="24" aria-hidden="true" viewBox="0 0 24 24" data-encore-id="icon"
        class="Svg-sc-ytk21e-0 gQUQL">
        <path d="M21 6H3V4h18v2zm0 14H3v-2h18v2zm0-7H3v-2h18v2z"></path>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    irTorneo(torneo) {
      if (this.$route.name == "ChampionshipListaTorneos") {
        location.href = "/championship/" + torneo + "/torneos/lista";
      } else {
        this.$router.push({ path: "/championship/" + torneo + "/torneos/lista" });
      }
    },
    irProfile(username) {
      if (this.$route.name == "perfilUser") {
        location.href = "/" + username;
      } else {
        this.$router.push({ path: "/" + username });
      }
    },
    logout() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href = "/";
        })
        .catch((error) => {
          toastr.error("Por favor recargue la pagina");
        });
    },
    ValidateNoti() {
      let url = this.$base.dominio + '/notificaciones/user';
      axios.post(url).then(res => {
        var countserve = res.data.length;
        localStorage.setItem('countlocal', countserve);
        this.$store.state.alertNotificacion = false;
        this.$store.state.countNotificacion = null;
      }).catch(e => {
        console.log(`error al cargar las notificaciones`);
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .menuSidebar svg {
    fill: white;
    right: 21px;
    margin-top: 17px;
    margin-left: 20px;
    margin-right: -25px;
  }

  .logoOnly {
    position: absolute !important;
    width: 46px !important;
    margin-top: 16px !important;
    left: 22px !important;
  }
}
</style>

<style lang="scss" scoped>
// .navTowAvatar {
//   .nombre-user {
//     font-weight: 600;
//     color: #2a2a2a;
//   }
//   .avatar {
//     height: 44px;
//     width: 44px;
//     background: #673ab7;
//     border-radius: 50px;
//     justify-content: center;
//     align-items: center;
//     font-size: 20px;
//     color: #eee;
//     margin-left: 15px;
//   }
// }

.noti2 {
  .img-torneo {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background-size: cover;
    background-position: center;
    margin-right: 13px;
  }

  .img-avatar {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background-size: cover;
    background-position: center;
    margin-right: 13px;
  }

  .img-avatar-not {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background: #5d5d5d;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    margin-right: 13px;
    color: white;
  }

  .notificacion-item {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background: #333;
    }
  }

  .dropdown-menu.show {
    width: 469px !important;
    left: -269px !important;

    .title {
      font-size: 20px;
      font-weight: 600;
      border-bottom: 1px solid #eee;
    }
  }
}

.avatar {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.navDashboard {
  position: fixed;
  top: 0;
  background: #121212 !important;
  right: 0;
  left: 0;
  height: 60px;
  box-shadow: 0px 1px 5px 1px #33333326;
  padding-left: 110px;
  padding-right: 110px;
  z-index: 10;

  .buscador-gamer {
    input {
      width: 514px;
      border-radius: 12px;
      background: #fff;
      border: none;
      height: 40px;
    }
  }
}

.dropdown-menu.show {
  background: #121212;
  color: white;
  top: 41px !important;
  padding: 10px;
  left: -88px !important;
  width: 201px;

  .dropdown-item {
    padding: 9px;
    border-radius: 9px;
    transition: 0.4s;

    &:hover {
      background: #333;
    }
  }

  .tipo {
    border-bottom: 1px solid;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    color: #8d8d8d;
  }
}

.menu-nav-dashboard {
  justify-content: center;
  align-items: center;
}

.nombre-user {
  font-weight: 600;
  color: #d5d5d5;
}

.avatar {
  height: 44px;
  width: 44px;
  background: #1e1e1e;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #eee;
  margin-left: 15px;
  box-shadow: 0px 0px 2px 1px #2f2f2f !important;
}

.container-nav-dashboard {
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .logo-dashboard {
    width: 115px;
  }
}

.NavTwo {
  z-index: 1;
  justify-content: end;
  height: 60px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #000000a8;
  padding-left: 254px;
  padding-right: 54px;

  ul {
    margin-bottom: 0;
    padding-bottom: 0;
    height: 100%;
    align-items: center;
  }

  button.login {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    background-color: var(#ffffff);
    color: var(#000000);
    display: flex;
    border-radius: 500px;
    font-size: inherit;
    min-block-size: 48px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-block: 8px;
    padding-inline: 32px;

    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-size: 1rem;
    font-weight: 700;
    font-family: var(--font-family,
        CircularSp,
        CircularSp-Arab,
        CircularSp-Hebr,
        CircularSp-Cyrl,
        CircularSp-Grek,
        CircularSp-Deva,
        var(--fallback-fonts, sans-serif));
    border: 0px;
    border-radius: 500px;
    cursor: pointer;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    touch-action: manipulation;
    transition-duration: 33ms;
    transition-property: background-color, border-color, color, box-shadow, filter,
      transform;
    user-select: none;
    vertical-align: middle;
    transform: translate3d(0px, 0px, 0px);
    min-inline-size: 0px;
    padding-block: 8px;
    min-block-size: 48px;
    padding-inline: 32px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }

  button.register {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-size: 1rem;
    font-weight: 700;
    font-family: var(--font-family,
        CircularSp,
        CircularSp-Arab,
        CircularSp-Hebr,
        CircularSp-Cyrl,
        CircularSp-Grek,
        CircularSp-Deva,
        var(--fallback-fonts, sans-serif));
    background-color: transparent;
    border: 0px;
    border-radius: 500px;
    cursor: pointer;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    touch-action: manipulation;
    transition-duration: 33ms;
    transition-property: background-color, border-color, color, box-shadow, filter,
      transform;
    user-select: none;
    vertical-align: middle;
    transform: translate3d(0px, 0px, 0px);
    color: var(--text-subdued, #6a6a6a);
    min-inline-size: 0px;
    padding-block: 8px;
    min-block-size: 48px;
    padding-inline: 32px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    &:hover {
      color: white;
    }
  }
}
</style>
