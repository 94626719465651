var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Dashboard",staticStyle:{"background":"#121212"}},[_c('Nav'),_c('NotificacionesM',{staticClass:"xs"}),_c('div',{staticClass:"contenido",style:(_vm.$route.name == 'perfilUser' ? { background: 'transparent' } : {})},[(
        _vm.$store.state.user &&
        _vm.$store.state.user.type == 'gamer' &&
        !_vm.$store.state.user.confirmation_token
      )?[_c('div',{staticClass:"pantallaBloqueo"},[_c('div',{staticClass:"nav d-flex",staticStyle:{"height":"60px","border-bottom":"1px solid rgb(238, 238, 238)","align-items":"center","padding":"0px 197px","justify-content":"space-between"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"menu-nav-dashboard d-flex flex-row",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"nombre-user mr-2"},[_vm._v(_vm._s(_vm.$store.state.user.username))]),_c('div',{staticClass:"menu-avatar"},[_c('div',{staticClass:"dropdown"},[_c('div',{attrs:{"type":"button","data-toggle":"dropdown","aria-expanded":"false"}},[(!_vm.$store.state.user.avatar)?_c('div',{staticClass:"avatar d-flex",style:({
                      'background-image': 'url(/img/iconos/avatar.png)',
                    })}):_vm._e(),(_vm.$store.state.user.avatar)?_c('div',{staticClass:"avatar d-flex",style:({
                      'background-image':
                        'url(' + _vm.$base.server + _vm.$store.state.user.avatar + ')',
                    })}):_vm._e()]),_c('div',{staticClass:"dropdown-menu"},[(_vm.$store.state.user.type != 'admin')?[_c('hr')]:_vm._e(),_c('a',{staticClass:"dropdown-item",staticStyle:{"color":"#333"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout()}}},[_vm._v("Cerrar Sesión "),_c('i',{staticClass:"fas fa-sign-out ml-2"})])],2)])])])]),_c('div',{staticClass:"centro d-flex flex-column"},[_c('img',{staticStyle:{"width":"152px","margin-bottom":"21px"},attrs:{"src":"/img/iconos/verificando.png","alt":""}}),_c('div',{staticClass:"title-pantall",staticStyle:{"font-size":"42px","text-transform":"uppercase"}},[_vm._v(" Cuenta en proceso de verificación ")]),_c('div',{staticClass:"small",staticStyle:{"font-size":"16px"}},[_vm._v(" Confirma tu cuenta con el enlace que enviamos a tu correo electrónico ")]),_c('br'),_c('small',[_vm._v("¿No te llego el correo electrónico? "),(!_vm.enviando)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.reenviarCorreoElectronico()}}},[_vm._v("Reenviar")]):_vm._e(),(_vm.enviando)?_c('a',{attrs:{"href":"#"}},[_vm._v("Enviando correo electrónico, por favor espere...")]):_vm._e()])])])]:_vm._e(),(!_vm.$store.state.cerrarModalGlobal && _vm.$store.state.buscarGlobal)?_c('div',{staticClass:"lg"},[_c('BuscadorGlobal')],1):_vm._e(),(_vm.$route.name != 'cuentaGamer' && _vm.$route.name != 'perfilUser')?[_c('Menu')]:_vm._e(),_c('router-view')],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"log"},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"122px"},attrs:{"src":"/img/logo/logo-negro.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-label lg"},[_c('i',{staticClass:"fab fa-modx mr-1"}),_c('strong',[_vm._v(" Panel Fan ")])])
}]

export { render, staticRenderFns }