<template>
  <div class="sidebarM" v-animate-css="'slideInRight'" v-if="$store.state.sidebarActive">
    <div class="boton-cerrar" @click.prevent="$store.state.sidebarActive = false">
      <i class="fal fa-times"></i>
    </div>

    <br />

    <div class="menu-sidebarM">
      <ul>
        <template v-if="!$store.state.user">
          <li class="grande" @click="$router.push({ name: 'Login' })">Iniciar Sesión</li>
          <li class="grande" @click="$router.push({ name: 'RegisterFan' })">Regístrate como Fan</li>
          <li class="grande" @click="$router.push({ name: 'RegisterCreator' })">Regístrate como Creador</li>
        </template>

        <div v-if="$store.state.user && $store.state.user.type == 'gamer'">
          <li
            @click="irRuta('dashboardGamer')"
            class="grande d-flex flex-row"
            style="align-items: center; color: white"
          >
            Streaming
          </li>

          <li
            @click="irRuta('Games')"
            class="grande d-flex flex-row"
            style="align-items: center; color: white"
          >
            Competición
          </li>
        </div>

        <div v-if="$store.state.user && $store.state.user.type == 'influencer'">
          <li
            @click="irRuta('dashboardInfluencer')"
            class="d-flex flex-row"
            style="align-items: center; color: white"
          >
            <div style="width: 25%">
              <i class="fab fa-modx mr-2" style="font-size: 23px"></i>
            </div>
            <strong>Panel</strong>
          </li>
        </div>

        <div v-if="$store.state.user && $store.state.user.type == 'admin'">
          <li
            @click="irRuta('dashboardAdmin')"
            class="d-flex flex-row"
            style="align-items: center; color: white"
          >
            <div style="width: 25%">
              <i class="fab fa-modx mr-2" style="font-size: 23px"></i>
            </div>
            <strong>Panel</strong>
          </li>
        </div>

        <div class="dividor"></div>

        <a href="https://ecuatro.es/politica-de-privacidad.html" target="_blank"><li class="small">Privacidad</li></a>
       <a href="https://ecuatro.es/terminos-y-condiciones.html" target="_blank"><li class="small">Términos y condiciones</li></a> 
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    irRuta(ruta) {
      this.$router.push({ name: ruta });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .sidebarM {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #080808;
    z-index: 100;

    .boton-cerrar {
      position: absolute;
      right: 38px;
      top: 21px;
      color: white;
      font-size: 31px;
    }

    .dividor {
      height: 1px;
      width: 70%;
      background: #ff5722;
      margin-top: 32px;
      margin-bottom: 30px;
    }

    .menu-sidebarM {
      text-align: left;
      padding: 49px;
      color: white;
      ul {
        margin-top: 20px;
        a {
      color: #646464;
    }
    
      }

      li.small {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      li.grande {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 13px;
      }
    }
  }
}
</style>
