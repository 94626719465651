<template>
  <div class="body">
    <!-- NAV  -->
    <div
      class="f-navigation nav"
    >
      <div class="f-navigation-container">
        <a href="#" class="f-navigation-logo-link inline-block"
          ><img
            src="/img/logo/logo-min4.png"
            loading="lazy"
            width="124"
            alt=""
            class="f-logo"
        /></a>
        <nav role="navigation" class="f-navigation-menu nav-menu">
          <a href="#" class="f-navigation-link nav-link">SOY STREAMER</a>
          <a href="#" class="f-navigation-link nav-link">BUSCAR</a>
          <a href="#" class="f-navigation-link nav-link">MODO DE JUEGO</a>
        </nav>
        <div class="f-navigation-content">
          <div class="f-navigation-menu-button nav-button">
            <div class="icon icon-nav-menu"></div>
          </div>
          <a
            href="#"
            @click.prevent="$router.push({name: 'ContentHomeTwo'})"
            class="f-navigation-button inline-block"
          >
            <div>ENTRAR</div>
          </a>
        </div>
      </div>
    </div>
    <!-- NAV -->
    

    <div class="section-2 wf-section">
      <div
        data-w-id="740b6cf6-0b05-fbe0-05d5-97bedc62512a"
        class="columns-4 row"
      >
        <div
          class="column-2 col col-7 col-stack col-small-small-stack col-tiny-tiny-stack"
        >
        <img src="https://uploads-ssl.webflow.com/6433c05821ae138eec36b742/6433c05821ae13298336b778_menu-icon.png" loading="lazy" data-wf-id="[&quot;5f835b7c-2fe8-790e-5b1e-b1e474327fb8&quot;]" data-w-id="5f835b7c-2fe8-790e-5b1e-b1e474327fb8" alt="">

          <h1 class="heading-12">
            <strong class="bold-text-2"
              ><em class="italic-text"
                >Juega con o contra tu creador favorito como nunca antes</em
              ></strong
            >
          </h1>
          <div class="text-block-5">Vive una experiencia única</div>
          <a href="#" class="link-9 clearfix"
            ><strong class="bold-text-5">Entrar ⇀</strong></a
          >
        </div>
        <div
          class="column clearfix col col-5 col-stack col-small-small-stack col-tiny-tiny-stack"
        >
          <div class="collage"></div>
          <div class="collage coll3"></div>
          <div class="collage coll2"></div>
          <div class="collage coll1"></div>
        </div>
      </div>
    </div>
    <div
      data-w-id="8cefd3d5-8ebd-faf5-f2e7-57257373f013"
      class="section-6 wf-section"
    >
      <h1 class="heading-2">
        <strong class="bold-text-9">Nuestra propuesta de valor</strong>
      </h1>
      <p class="paragraph">
        Únete a la comunidad de eCuatro y disfruta de la interacción en tiempo real con
        tus streamers favoritos. Descubre un nuevo mundo de experiencias gaming
        interactivas
      </p>
    </div>
    <div
      data-w-id="5e9c4a72-a838-9ad4-1e31-48f16a3c6cf5"
     
      class="section-7 wf-section"
    >
      <p class="paragraph-10">
        <em class="italic-text-7"
          >&quot;eCuatro es una plataforma que ofrece a los gamers y fans interactuar con
          los creadores de contenido a través de diferentes experiencias&quot;</em
        >
      </p>
      <p class="paragraph-3">Conoce los Creadores de Contenido y competiciones activas</p>
      <a href="https://beta.ecuatro.es/dashboard/gamer/" target="_blank" class="link"
        ><strong>Descubre ⇀</strong></a
      >
    </div>
    <div
      data-w-id="7ef9c47f-9a6a-c108-4a5d-079dc7cee3a7"
     
      class="section-8 clearfix wf-section"
    >
      <div class="div-block-10">
        <h1 class="heading-5"><strong class="bold-text-7">Competiciones</strong></h1>
        <p class="paragraph-5">
          <em class="italic-text-5"
            >El Fan competirá por ganar el torneo del Creador de Contenido y jugar con o
            contra el Creador en su canal</em
          >
        </p>
      </div>
      <div class="div-block-11">
        <h1 class="heading-4"><strong class="bold-text-6">Experiencias</strong></h1>
        <p class="paragraph-4">
          <em class="italic-text-4"
            >El Fan podrá reservar tiempo en el canal del Creador de Contenido y vivir una
            experiencia única</em
          >
        </p>
      </div>
    </div>
    <div class="section-3 wf-section">
      <div
        data-w-id="1f0a505c-a223-ccc5-71f1-3b34f99609f7"
       
        class="div-block-12"
      >
        <h1 class="heading-14">
          <strong class="bold-text-10"
            >Vive la experiencia Gaming como nunca antes</strong
          >
        </h1>
      </div>
    </div>
    <div
      data-w-id="c4664599-074d-5018-2582-fb1a14474e41"
     
      class="wf-section"
    >
      <h1 class="heading-9">
        <strong><em>Creadores en eCuatro</em></strong>
      </h1>
      <div class="row">
        <div class="column-11 col col-4">
          <div class="div-block-4 streamers">
            <img
              src="images/ARSILEX.jpg"
              loading="lazy"
              width="203"
              alt=""
              class="image-2"
            />
            <h1 class="heading-6">Arsilex</h1>
            <a
              href="https://beta.ecuatro.es/Arsilex"
              target="_blank"
              class="link-3 link-4"
              >Ver más</a
            >
          </div>
        </div>
        <div class="col col-4">
          <div class="streamers clearfix">
            <img
              src="images/BERS.jpg"
              loading="lazy"
              width="200"
              height="200"
              alt=""
              class="image-3"
            />
            <h1 class="heading-7">Bersgamer</h1>
            <a
              href="https://beta.ecuatro.es/Bersgamer"
              target="_blank"
              class="link-2 link-4"
              >Ver más</a
            >
          </div>
        </div>
        <div class="column-10 col col-4">
          <div class="streamers">
            <img
              src="images/MOYORZ.jpg"
              loading="lazy"
              width="200"
              height="200"
              alt=""
              class="image-4"
            />
            <h1 class="heading-8">Moyorz87</h1>
            <a href="https://beta.ecuatro.es/Moyorz87" target="_blank" class="link-4"
              >Ver más</a
            >
          </div>
        </div>
      </div>
      <div class="div-block-5">
        <h3 class="heading-10"><em>Próximamente en eCuatro...</em></h3>
        <div class="columns-2 row">
          <div class="column-6 col col-3 col-small-6 col-tiny-6">
            <img src="images/whitezu.jpg" loading="lazy" alt="" class="image-5" />
          </div>
          <div class="column-7 col col-3 col-small-6 col-tiny-6">
            <img src="images/miss.jpg" loading="lazy" alt="" class="image-5" />
          </div>
          <div class="column-8 col col-3 col-small-6 col-tiny-6">
            <img src="images/martu.jpg" loading="lazy" alt="" class="image-5" />
          </div>
          <div class="column-9 col col-3 col-small-6 col-tiny-6">
            <img src="images/little.jpg" loading="lazy" alt="" class="image-5" />
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <section
      data-w-id="077791d6-6c24-6ec0-5143-33bfe9b3b36d"
     
      class="uui-section_testimonial06"
    >
      <div class="uui-page-padding">
        <div class="uui-container-large">
          <div class="uui-padding-vertical-xhuge">
            <h1 class="heading-13">
              <strong><em>Testimonios</em></strong>
            </h1>
            <div
              data-delay="4000"
              data-animation="slide"
              class="uui-testimonial06_component slider"
              data-autoplay="false"
              data-easing="ease"
              data-hide-arrows="false"
              data-disable-swipe="false"
              data-autoplay-limit="0"
              data-nav-spacing="6"
              data-duration="500"
              data-infinite="true"
            >
              <div class="uui-testimonial06_mask testimonios slider-mask">
                <div class="uui-testimonial06_slide slide">
                  <div class="uui-testimonial06_content">
                    <div class="uui-testimonial06_rating-wrapper">
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <h3 class="uui-heading-medium text-weight-medium">
                      Esta plataforma es una revolución para los fans de los creadores. No
                      solo puedo apoyar económicamente a los que más me gustan, sino que
                      también puedo interactuar con ellos y con otros fans en un ambiente
                      seguro y divertido.
                    </h3>
                    <div class="uui-testimonial06_client">
                      <div class="uui-testimonial06_client-image-wrapper">
                        <div class="f-avatar-regular">
                          <img
                            src="images/Avatar-04.png"
                            loading="lazy"
                            alt=""
                            class="f-avatar-image"
                          />
                        </div>
                      </div>
                      <div class="uui-testimonial06_client-info">
                        <div class="uui-heading-tiny">Cris Jones</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uui-testimonial06_slide slide">
                  <div class="uui-testimonial06_content">
                    <div class="uui-testimonial06_rating-wrapper">
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <h3 class="uui-heading-medium text-weight-medium">
                      Esta plataforma me ha dado la libertad creativa que necesitaba.
                      Ahora puedo crear el contenido que realmente me gusta, sin depender
                      de algoritmos. Además, conecto con mis fans más leales y tienen
                      ventajas exclusivas por su apoyo. Es una forma de agradecerles su
                      fidelidad.
                    </h3>
                    <div class="uui-testimonial06_client">
                      <div class="uui-testimonial06_client-image-wrapper">
                        <div class="f-avatar-regular-2">
                          <img
                            src="images/chicaJugando.png"
                            loading="lazy"
                            alt=""
                            class="f-avatar-image-2"
                          />
                        </div>
                      </div>
                      <div class="uui-testimonial06_client-info">
                        <div class="uui-heading-tiny">Lily Jackson</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uui-testimonial06_slide slide">
                  <div class="uui-testimonial06_content">
                    <div class="uui-testimonial06_rating-wrapper">
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <h3 class="uui-heading-medium text-weight-medium">
                      Me encanta esta plataforma porque me permite conectar con mis
                      creadores de contenido favoritos de una forma más íntima y personal.
                      Puedo ver sus vídeos exclusivos y participar en sus retransmisiones
                      en directo. Es como ser parte de su familia.
                    </h3>
                    <div class="uui-testimonial06_client">
                      <div class="uui-testimonial06_client-image-wrapper">
                        <div class="f-avatar-regular-2">
                          <img
                            src="images/Avatar-02.png"
                            loading="lazy"
                            alt=""
                            class="f-avatar-image-2"
                          />
                        </div>
                      </div>
                      <div class="uui-testimonial06_client-info">
                        <div class="uui-heading-tiny">Oliver James</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uui-testimonial06_slide slide">
                  <div class="uui-testimonial06_content">
                    <div class="uui-testimonial06_rating-wrapper">
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <h3 class="uui-heading-medium text-weight-medium">
                      Si eres fan de algún creador de contenido, esta plataforma es para
                      ti. Aquí podrás acceder a contenido exclusivo que no encontrarás en
                      ninguna otra parte. También podrás comunicarte con los creadores y
                      conocerlos mejor.
                    </h3>
                    <div class="uui-testimonial06_client">
                      <div class="uui-testimonial06_client-image-wrapper">
                        <div class="f-avatar-regular-2">
                          <img
                            src="images/Avatar-Image-4.png"
                            loading="lazy"
                            alt=""
                            class="f-avatar-image-2"
                          />
                        </div>
                      </div>
                      <div class="uui-testimonial06_client-info">
                        <div class="uui-heading-tiny">Kelly Williams</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uui-testimonial06_slide slide">
                  <div class="uui-testimonial06_content">
                    <div class="uui-testimonial06_rating-wrapper">
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="uui-testimonial06_rating-icon embed">
                        <svg
                          width="20"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_28_8746)">
                            <path
                              d="M9.53834 1.60996C9.70914 1.19932 10.2909 1.19932 10.4617 1.60996L12.5278 6.57744C12.5998 6.75056 12.7626 6.86885 12.9495 6.88383L18.3123 7.31376C18.7556 7.3493 18.9354 7.90256 18.5976 8.19189L14.5117 11.6919C14.3693 11.8139 14.3071 12.0053 14.3506 12.1876L15.5989 17.4208C15.7021 17.8534 15.2315 18.1954 14.8519 17.9635L10.2606 15.1592C10.1006 15.0615 9.89938 15.0615 9.73937 15.1592L5.14806 17.9635C4.76851 18.1954 4.29788 17.8534 4.40108 17.4208L5.64939 12.1876C5.69289 12.0053 5.6307 11.8139 5.48831 11.6919L1.40241 8.19189C1.06464 7.90256 1.24441 7.3493 1.68773 7.31376L7.05054 6.88383C7.23744 6.86885 7.40024 6.75056 7.47225 6.57744L9.53834 1.60996Z"
                              fill="currentColor"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <h3 class="uui-heading-medium text-weight-medium">
                      Esta plataforma es el espacio perfecto para los creadores de
                      contenido. Aquí puedo monetizar mi trabajo de una forma sostenible y
                      transparente, sin intermediarios ni comisiones abusivas. Puedo crear
                      una comunidad implicada y cercana con mis fans.
                    </h3>
                    <div class="uui-testimonial06_client">
                      <div class="uui-testimonial06_client-image-wrapper">
                        <div class="f-avatar-regular-2">
                          <img
                            src="images/Avatar-05.png"
                            loading="lazy"
                            alt=""
                            class="f-avatar-image-2"
                          />
                        </div>
                      </div>
                      <div class="uui-testimonial06_client-info">
                        <div class="uui-heading-tiny">Lyle Kauffman</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="uui-testimonial06_arrow hide-mobile-landscape slider-arrow-left"
              >
                <div class="uui-button-icon embed">
                  <svg
                    width="24"
                    height="24"
                    viewbox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 12H5M5 12L12 19M5 12L12 5"
                      stroke="CurrentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
              <div
                class="uui-testimonial06_arrow hide-mobile-landscape slider-arrow-right"
              >
                <div class="uui-button-icon embed">
                  <svg
                    width="24"
                    height="24"
                    viewbox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12H19M19 12L12 5M19 12L12 19"
                      stroke="CurrentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="uui-testimonial06_slide-nav slider-nav w-round"></div>
              <div class="slide-nav-styling-embed embed">
                <!-- <style>
                  /* Change color of the default dot by replacing the hex code*/
                  .slider-dot {
                    background-color: #f2f4f7;
                    width: 0.625rem;
                    height: 0.625rem;
                  }
                  /* Change color of the active dot by replacing the hex code*/
                  .slider-dot.active {
                    background-color: #6941c6;
                    width: 0.625rem;
                    height: 0.625rem;
                  }
                </style> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div data-w-id="96a82e14-1204-5bf6-19ca-2c5d69cf2f71">
      <h1 class="heading-15">Conoce todos los creadores de contenido</h1>
      <a href="https://beta.ecuatro.es/dashboard/gamer/" target="_blank" class="link-10"
        >Descubre <strong>⇀</strong></a
      >
    </div>
    <div
      data-w-id="16fb0d3a-0ee6-c055-6304-3f954d8d8abd"
     
      class="brix---section clearfix wf-section"
    >
      <div class="brix---mg-bottom-48px">
        <div
          data-w-id="16fb0d3a-0ee6-c055-6304-3f954d8d8ac0"
          style="
            -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
              rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
              rotateZ(0) skew(0, 0);
            transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
              rotateZ(0) skew(0, 0);
            opacity: 0;
          "
          class="brix---inner-container-574px---center"
        >
          <div class="brix---text-center">
            <div class="brix---mg-bottom-16px">
              <div class="brix---color-neutral-800">
                <h2 class="brix---heading-h2-size-2">
                  <em>Preguntas <br />frecuentes</em>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="brix---container-default container">
        <div
          data-w-id="16fb0d3a-0ee6-c055-6304-3f954d8d8ac9"
          style="
            -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0)
              rotateY(0) rotateZ(0) skew(0, 0);
            -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
              rotateZ(0) skew(0, 0);
            -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
              rotateZ(0) skew(0, 0);
            transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
              rotateZ(0) skew(0, 0);
            opacity: 0;
          "
          class="brix---inner-container-856---center"
        >
          <div class="layout-grid brix---grid-1-column-gap-row-32px">
            <div
              data-w-id="16fb0d3a-0ee6-c055-6304-3f954d8d8acb"
              class="brix---accordion-item-wrapper"
            >
              <div class="brix---accordion-side">
                <a href="#" class="brix---btn-circle-accordion-btn inline-block">
                  <div class="brix---accordion-btn-vertical-line"></div>
                  <div class="brix---accordion-btn-horizontal-line"></div>
                </a>
              </div>
              <div class="brix---accordion-content-wrapper">
                <div class="brix---accordion-header">
                  <div class="brix---color-neutral-800">
                    <h3 class="brix---accordion-title">¿Qué es eCuatro?</h3>
                  </div>
                </div>
                <div
                  style="
                    height: 0px;
                    -webkit-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    -moz-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    -ms-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    opacity: 0;
                  "
                  class="brix---acordion-body"
                >
                  <div class="brix---accordion-spacer"></div>
                  <div class="brix---color-neutral-600">
                    <p class="brix---paragraph-default">
                      eCuatro es una plataforma que conecta a los creadores/as de
                      contenido de videojuegos con sus fans a través de diferentes
                      servicios ofrecidos para el creador/a. Nuestra misión es dar las
                      herramientas al creador/a de contenido que le permitan vivir de su
                      pasión y vivir una experiencia única junto a fans y gamers en un
                      entorno digital.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-id="16fb0d3a-0ee6-c055-6304-3f954d8d8ada"
              class="brix---accordion-item-wrapper"
            >
              <div class="brix---accordion-side">
                <a href="#" class="brix---btn-circle-accordion-btn inline-block">
                  <div class="brix---accordion-btn-vertical-line"></div>
                  <div class="brix---accordion-btn-horizontal-line"></div>
                </a>
              </div>
              <div class="brix---accordion-content-wrapper">
                <div class="brix---accordion-header">
                  <div class="brix---color-neutral-800">
                    <h3 class="brix---accordion-title">
                      ¿En qué consiste el sistema de reserva de tiempo con el creador/a?
                    </h3>
                  </div>
                </div>
                <div
                  style="
                    height: 0px;
                    -webkit-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    -moz-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    -ms-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    opacity: 0;
                  "
                  class="brix---acordion-body"
                >
                  <div class="brix---accordion-spacer"></div>
                  <div class="brix---color-neutral-600">
                    <p class="brix---paragraph-default">
                      Es un sistema independiente a las competiciones en donde los fans
                      pueden vivir una experiencia única junto con el creador de contenido
                      en su canal.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-id="16fb0d3a-0ee6-c055-6304-3f954d8d8ae9"
              class="brix---accordion-item-wrapper"
            >
              <div class="brix---accordion-side">
                <a href="#" class="brix---btn-circle-accordion-btn inline-block">
                  <div class="brix---accordion-btn-vertical-line"></div>
                  <div class="brix---accordion-btn-horizontal-line"></div>
                </a>
              </div>
              <div class="brix---accordion-content-wrapper">
                <div class="brix---accordion-header">
                  <div class="brix---color-neutral-800">
                    <h3 class="brix---accordion-title">
                      ¿Cuál es la edad mínima para poder solicitar una partida directa con
                      el Creador?
                    </h3>
                  </div>
                </div>
                <div
                  style="
                    height: 0px;
                    -webkit-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    -moz-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    -ms-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    opacity: 0;
                  "
                  class="brix---acordion-body"
                >
                  <div class="brix---accordion-spacer"></div>
                  <div class="brix---color-neutral-600">
                    <p class="brix---paragraph-default">
                      La edad mínima para participar será de 16 años. En el caso de ser
                      menor de 16 años, necesitarás la autorización de tus padres para
                      poder jugar en el canal del creador de contenido.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-id="16fb0d3a-0ee6-c055-6304-3f954d8d8af8"
              class="brix---accordion-item-wrapper"
            >
              <div class="brix---accordion-side">
                <a href="#" class="brix---btn-circle-accordion-btn inline-block">
                  <div class="brix---accordion-btn-vertical-line"></div>
                  <div class="brix---accordion-btn-horizontal-line"></div>
                </a>
              </div>
              <div class="brix---accordion-content-wrapper">
                <div class="brix---accordion-header">
                  <div class="brix---color-neutral-800">
                    <h3 class="brix---accordion-title">
                      ¿Qué pasa si no se presenta mi contrincante?
                    </h3>
                  </div>
                </div>
                <div
                  style="
                    height: 0px;
                    -webkit-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    -moz-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    -ms-transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    transform: translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1)
                      rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                    opacity: 0;
                  "
                  class="brix---acordion-body"
                >
                  <div class="brix---accordion-spacer"></div>
                  <div class="brix---color-neutral-600">
                    <p class="brix---paragraph-default">
                      En caso de que tu rival no se presente, deberás abrir un ticket de
                      protesta de tu partido solicitando la victoria por defecto. De esta
                      manera, la organización estudiará tu caso y te pasará a la siguiente
                      ronda si es así.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="#" class="link-11"><strong>Ver todas las FAQs</strong></a>
      </div>
    </div>
    <div data-w-id="40df5eea-b5a5-31d2-08ba-bd8d87737971" class="background">
      <div class="section-5 wf-section">
        <footer class="brix---footer-wrapper"></footer>
      </div>
      <footer class="brix---footer-wrapper-2">
        <div class="brix---container-default-3 w-container">
          <div class="brix---footer-bottom-v4">
            <div class="row">
              <div class="clearfix col col-6">
                <div class="text-block-4">Powered by:</div>
                <a
                  id="node-c4e7b583-639d-a936-e5ce-f6c5d69355fb-7136b745"
                  href="#"
                  class="brix---footer-logo-wrapper inline-block clearfix"
                  ><img
                    src="images/logo-lanzadera.png"
                    srcset="
                      images/logo-lanzadera-p-500.png   500w,
                      images/logo-lanzadera-p-800.png   800w,
                      images/logo-lanzadera-p-1080.png 1080w,
                      images/logo-lanzadera-p-1600.png 1600w,
                      images/logo-lanzadera.png        2872w
                    "
                    width="253"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 239px, 253px"
                    alt=""
                    class="brix---footer-logo"
                /></a>
              </div>
              <div class="col col-6">
                <div class="columns-6 row">
                  <div class="col col-3 col-small-3 col-tiny-6">
                    <p class="paragraph-9">
                      <sup class="superscript">Empresas colaboradoras:</sup>
                    </p>
                    <img
                      src="images/Logo-Galaga-Blanco.webp"
                      loading="lazy"
                      width="141"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 22vw, 12vw"
                      srcset="
                        images/Logo-Galaga-Blanco-p-500.webp 500w,
                        images/Logo-Galaga-Blanco.webp       560w
                      "
                      alt=""
                      class="image-8"
                    />
                  </div>
                  <div class="col col-3 col-small-3 col-tiny-6">
                    <img
                      src="images/image-3.png"
                      loading="lazy"
                      width="94"
                      sizes="(max-width: 479px) 100vw, (max-width: 767px) 94px, (max-width: 991px) 11vw, 94px"
                      srcset="images/image-3-p-500.png 500w, images/image-3.png 507w"
                      alt=""
                      class="image-10"
                    />
                  </div>
                  <div class="col col-3 col-small-3 col-tiny-6">
                    <img
                      src="images/CGS-BLANCO.png"
                      loading="lazy"
                      width="87"
                      sizes="(max-width: 479px) 100vw, 87px"
                      srcset="
                        images/CGS-BLANCO-p-500.png   500w,
                        images/CGS-BLANCO-p-800.png   800w,
                        images/CGS-BLANCO-p-1080.png 1080w,
                        images/CGS-BLANCO-p-1600.png 1600w,
                        images/CGS-BLANCO.png        1920w
                      "
                      alt=""
                      class="image-11"
                    />
                  </div>
                  <div class="col col-3 col-small-3 col-tiny-6">
                    <img
                      src="images/Logo_TLP_Tenerife_Vertical_Blanco.png"
                      loading="lazy"
                      width="81"
                      sizes="(max-width: 479px) 100vw, 81px"
                      srcset="
                        images/Logo_TLP_Tenerife_Vertical_Blanco-p-500.png  500w,
                        images/Logo_TLP_Tenerife_Vertical_Blanco-p-800.png  800w,
                        images/Logo_TLP_Tenerife_Vertical_Blanco.png       1000w
                      "
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div class="brix---container-default-2 container">
        <div class="brix---footer-small">
          <div class="layout-grid brix---grid-footer-v11">
            <div
              id="node-d0e132bc-1d2c-4309-8f3a-84aa181623e7-7136b745"
              class="brix---text-left---t-center"
            >
              <div class="brix---color-neutral-801">
                <div class="columns-7 row">
                  <div class="column-13 col col-2 col-small-2 col-tiny-4">
                    <a
                      href="https://www.instagram.com/ecuatro_oficial/"
                      class="inline-block"
                      ><img
                        src="images/ig.png"
                        loading="lazy"
                        width="30"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 4vw, 30px"
                        srcset="
                          images/ig-p-500.png   500w,
                          images/ig-p-800.png   800w,
                          images/ig-p-1080.png 1080w,
                          images/ig-p-1600.png 1600w,
                          images/ig-p-2000.png 2000w,
                          images/ig-p-2600.png 2600w,
                          images/ig-p-3200.png 3200w,
                          images/ig.png        3333w
                        "
                        alt=""
                    /></a>
                  </div>
                  <div class="column-17 col col-2 col-small-2 col-tiny-4">
                    <a href="https://twitter.com/eCuatro_oficial" class="inline-block"
                      ><img
                        src="images/tw.png"
                        loading="lazy"
                        width="46"
                        alt=""
                        class="image-21"
                    /></a>
                  </div>
                  <div class="column-14 col col-2 col-small-2 col-tiny-4">
                    <a href="#" class="link-block-2 inline-block"
                      ><img
                        src="images/tiktok.png"
                        loading="lazy"
                        alt=""
                        class="image-17"
                    /></a>
                  </div>
                  <div class="column-15 col col-2 col-small-2 col-tiny-4">
                    <a href="#" class="inline-block"
                      ><img
                        src="images/yt.png"
                        loading="lazy"
                        width="30"
                        alt=""
                        class="image-18"
                    /></a>
                  </div>
                  <div class="column-16 col col-2 col-small-2 col-tiny-4">
                    <a href="#" class="inline-block"
                      ><img
                        src="images/discor.png"
                        loading="lazy"
                        width="30"
                        alt=""
                        class="image-20"
                    /></a>
                  </div>
                  <div class="column-12 col col-2 col-small-2 col-tiny-4">
                    <a href="#" class="link-block inline-block"
                      ><img
                        src="images/gm.png"
                        loading="lazy"
                        width="30"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 5vw, 40.328125px"
                        srcset="images/gm-p-500.png 500w, images/gm.png 643w"
                        alt=""
                        class="image-19"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="node-d0e132bc-1d2c-4309-8f3a-84aa181623f6-7136b745"
              class="brix---text-right---t-center"
            >
              <p class="brix---paragraph-default-2 clearfix">
                <a href="#" class="link-5">Términos y condicion</a>
                <a href="#" class="link-6">Política de Privacidad </a>
                <a href="#" class="link-7">Política de Cookies </a>
                <a href="#" class="link-8">CREADORES DE CONTENIDO</a>
              </p>
              <div class="brix---color-neutral-801"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.$store.state.user) {
      SocketioService.socket.on("evento-" + "1", (msg) => {
        console.log(msg);
        //   $("#audio").prop("muted", "");
        //   $("#audio")[0].play();

        //   this.$funtion.Notifications();
        //   this.$notify({
        //     title: msg.title,
        //     text: msg.mensaje,
        //     data: msg,
        //     group: "foo",
        //     duration: 5000,
        //     classes: "notifications-active",
        //   });
      });
    }
  },
  methods: {
    eventNotification() {
      const datos = {
        user_id: 1,
        event: "notication",
      };
      var noti = SocketioService.socket.emit("notification", datos);
      console.log(noti);
    },
  },
};
</script>
<style lang="scss" scoped>
  .nav {
    padding: 0 !important;
  }

  .column-2 {
        text-align: left;
    color: white;
    .italic-text{
   color:white !important;
  }
  }
  .italic-text{
   color:white !important;
  }
</style>

<style lang="scss" scoped>
.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.body {
  font-family: Montserrat, sans-serif;
  color: #1a1b1f;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  background:red;
}

h1 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 44px;
  line-height: 62px;
  font-weight: 400;
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 50px;
  font-weight: 400;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 46px;
  font-weight: 400;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 38px;
  font-weight: 400;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 34px;
  font-weight: 500;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
}

p {
  margin-bottom: 10px;
}

a {
  display: block;
  transition: opacity 200ms ease;
  color: #1a1b1f;
  text-decoration: underline;
}

a:hover {
  color: #32343a;
}

a:active {
  color: #43464d;
}

ul {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 40px;
  list-style-type: disc;
}

li {
  margin-bottom: 10px;
}

img {
  display: block;
}

label {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

blockquote {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 15px 30px;
  border-left: 5px solid #e2e2e2;
  font-size: 20px;
  line-height: 34px;
}

figcaption {
  margin-top: 5px;
  opacity: 1;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
}

.divider {
  height: 1px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: #eee;
}

.styleguide-content-wrap {
  text-align: center;
}

.section {
  margin-right: 3%;
  margin-left: 3%;
}

.section.cc-cta {
  padding-right: 80px;
  padding-left: 80px;
  background-color: #f4f4f4;
}

.section.cc-store-home-wrap {
  margin-right: 5%;
  margin-left: 5%;
}

.styleguide-block {
  display: block;
  margin-top: 80px;
  margin-bottom: 80px;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.paragraph-tiny {
  font-size: 12px;
  line-height: 20px;
}

.paragraph-bigger {
  margin-bottom: 10px;
  opacity: 1;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
}

.paragraph-bigger.cc-bigger-light {
  opacity: 0.6;
}

.paragraph-bigger.cc-bigger-white-light {
  opacity: 0.6;
}

.button {
  width: 20%;
  margin-right: 13px;
  margin-left: 5%;
  padding: 2% 5%;
  float: right;
  flex: 0 auto;
  border-radius: 0px;
  background-color: #1a1b1f;
  transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.button:hover {
  background-color: #32343a;
  color: #fff;
}

.button:active {
  background-color: #43464d;
}

.button.cc-jumbo-button {
  padding: 16px 35px;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-jumbo-button.cc-jumbo-white {
  background-color: #fff;
  color: #000;
}

.button.cc-jumbo-button.cc-jumbo-white:hover {
  background-color: #f1f1f1;
}

.button.cc-jumbo-button.cc-jumbo-white:active {
  background-color: #e1e1e1;
}

.button.cc-contact-us {
  position: relative;
  z-index: 5;
  width: 60%;
  height: 72%;
  margin-right: auto;
  padding: 4% 3%;
  border-radius: 15px;
  background-color: #eb601d;
}

.button.cc-contact-us.a {
  position: static;
  display: block;
  width: 60%;
  padding: 4% 0%;
  clear: none;
  -ms-grid-row-align: center;
  align-self: center;
  flex: 0 auto;
  border-radius: 15px;
  background-color: #101214;
}

.button.cc-white-button {
  padding: 16px 35px;
  background-color: #fff;
  color: #202020;
  font-size: 14px;
  line-height: 26px;
}

.button.cc-white-button:hover {
  background-color: hsla(0, 0%, 100%, 0.8);
}

.button.cc-white-button:active {
  background-color: hsla(0, 0%, 100%, 0.9);
}

.label {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.label.cc-styleguide-label {
  margin-bottom: 25px;
}

.label.cc-light {
  opacity: 0.6;
}

.label.cc-blog-date {
  margin-top: 20px;
  opacity: 0.6;
}

.heading-jumbo-tiny {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  text-transform: uppercase;
}

.rich-text {
  width: 70%;
  margin-right: auto;
  margin-bottom: 100px;
  margin-left: auto;
}

.rich-text p {
  margin-top: 15px;
  margin-bottom: 25px;
  opacity: 0.6;
}

.rich-text figcaption {
  opacity: 0.6;
}

.rich-text figure {
  margin-top: 25px;
  padding-bottom: 20px;
}

.paragraph-light {
  opacity: 0.6;
}

.heading-jumbo {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 64px;
  line-height: 80px;
  text-transform: none;
}

.heading-jumbo-small {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 50px;
  font-weight: 400;
  text-transform: none;
}

.styleguide-button-wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.styleguide-header-wrap {
  display: flex;
  height: 460px;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1a1b1f;
  color: #fff;
  text-align: center;
}

.paragraph-small {
  font-size: 14px;
  line-height: 26px;
}

.logo-link {
  z-index: 1;
}

.logo-link:hover {
  opacity: 0.8;
}

.logo-link:active {
  opacity: 0.7;
}

.menu {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  display: flex;
  margin-bottom: -1px;
  padding-right: 0px;
  padding-bottom: 5%;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.navigation-wrap {
  position: relative;
  display: flex;
  margin-top: 2px;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.navigation {
  display: flex;
  height: 120%;
  margin-top: 0px;
  padding: 3% 0px;
  align-items: center;
  background-color: #000;
}

.te {
  left: auto;
  display: block;
  width: 30%;
  margin-top: 15%;
  margin-left: 15%;
  padding: 5% 0%;
  flex-direction: row;
  align-items: flex-end;
  -ms-grid-row-align: center;
  align-self: center;
  order: 0;
  flex: 0 auto;
  opacity: 0.85;
  font-family: Barlow, sans-serif;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.te:hover {
  opacity: 0.9;
}

.te:active {
  opacity: 0.8;
}

.te.w--current {
  opacity: 1;
  color: #1a1b1f;
  font-weight: 600;
}

.te.w--current:hover {
  opacity: 0.8;
  color: #32343a;
}

.te.w--current:active {
  opacity: 0.7;
  color: #32343a;
}

.logo-image {
  display: block;
  margin-top: 0%;
  margin-right: 50%;
  padding-top: 0%;
  padding-bottom: 0px;
}

.navigation-items {
  display: flex;
  margin-left: 20%;
  padding-left: 0%;
  clear: left;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  flex: 0 0 auto;
}

.footer-wrap {
  display: flex;
  padding-top: 70px;
  padding-bottom: 70px;
  justify-content: center;
  align-items: center;
}

.webflow-link {
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: opacity 400ms ease;
  text-decoration: none;
}

.webflow-link:hover {
  opacity: 1;
}

.webflow-link:active {
  opacity: 0.8;
}

.webflow-logo-tiny {
  margin-top: -2px;
  margin-right: 8px;
}

.cta-text {
  width: 70%;
  margin-right: auto;
  margin-bottom: 35px;
  margin-left: auto;
}

.cta-wrap {
  display: flex;
  padding-top: 110px;
  padding-bottom: 120px;
  justify-content: center;
  text-align: center;
}

.intro-header {
  display: block;
  height: 620px;
  margin-bottom: 0%;
  padding-top: 0%;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto 233px;
  grid-template-rows: auto 233px;
  background-color: transparent;
  color: #fff;
}

.intro-header.cc-subpage {
  height: 480px;
  background-color: #000;
  background-image: none;
  color: #fff;
}

.intro-content {
  width: 70%;
  max-width: 1140px;
  text-align: center;
}

.intro-content.cc-homepage {
  margin-bottom: 20px;
}

.motto-wrap {
  width: 80%;
  margin: 100px auto 85px;
  text-align: center;
}

.about-story-wrap {
  width: 80%;
  margin: 80px auto;
  text-align: center;
}

.our-services-grid {
  margin-bottom: 120px;
  grid-column-gap: 80px;
  grid-row-gap: 60px;
  -ms-grid-rows: auto 60px auto;
  grid-template-areas:
    ". . ."
    ". . .";
  -ms-grid-columns: 1fr 80px 1fr 80px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.section-heading-wrap {
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
}

.service-icon {
  margin-bottom: 30px;
}

.status-message {
  padding: 9px 30px;
  background-color: #202020;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
}

.status-message.cc-success-message {
  background-color: #12b878;
}

.status-message.cc-error-message {
  background-color: #db4b68;
}

.status-message.cc-no-data {
  display: block;
  width: 70%;
  margin: 100px auto;
}

.contact-form-wrap {
  padding: 45px 50px 50px;
  border-style: solid;
  border-width: 1px;
  border-color: #eee;
}

.contact-form-grid {
  margin-top: 100px;
  margin-bottom: 100px;
  align-items: start;
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template-areas: ". .";
  -ms-grid-columns: 2.5fr 80px 1fr;
  grid-template-columns: 2.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.details-wrap {
  margin-bottom: 30px;
}

.get-in-touch-form {
  display: flex;
  flex-direction: column;
}

.text-field {
  margin-bottom: 18px;
  padding: 21px 20px;
  border: 1px solid #e4e4e4;
  border-radius: 0px;
  transition: border-color 400ms ease;
  font-size: 14px;
  line-height: 26px;
}

.text-field:hover {
  border-color: #e3e6eb;
}

.text-field:active {
  border-color: #43464d;
}

.text-field:focus {
  border-color: #43464d;
}

.text-field::-moz-placeholder {
  color: rgba(50, 52, 58, 0.4);
}

.text-field::-ms-input-placeholder {
  color: rgba(50, 52, 58, 0.4);
}

.text-field::placeholder {
  color: rgba(50, 52, 58, 0.4);
}

.text-field.cc-contact-field {
  margin-bottom: 25px;
}

.text-field.cc-textarea {
  height: 200px;
  padding-top: 12px;
}

.contact-form {
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  flex-direction: column;
  align-items: stretch;
}

.contact-form-heading-wrap {
  margin-bottom: 40px;
}

.contact-heading {
  margin-top: 0px;
  margin-bottom: 15px;
  font-weight: 400;
}

.map {
  height: 460px;
  margin-bottom: 100px;
  line-height: 20px;
}

.contact-team-name-wrap {
  margin-bottom: 30px;
}

.our-contacts {
  margin-bottom: 120px;
  grid-column-gap: 80px;
  grid-row-gap: 60px;
  grid-template-areas: ". . .";
  -ms-grid-columns: 1fr 80px 1fr 80px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  text-align: center;
}

.contact-team-details-wrap {
  margin-top: 30px;
}

.contact-team-pic {
  height: 150px;
  margin-bottom: 30px;
  background-color: #f4f4f4;
}

.team-pic {
  width: 100%;
  height: 420px;
  margin-bottom: 40px;
  background-color: #f4f4f4;
}

.team-members {
  margin-bottom: 60px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-rows: auto 30px auto;
  grid-template-areas:
    ". . ."
    ". . .";
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.team-member-title-wrap {
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-member-name {
  margin-bottom: 5px;
  opacity: 1;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
}

.projects-grid {
  margin-bottom: 60px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-areas:
    "."
    "."
    ".";
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto 30px auto 30px auto;
  grid-template-rows: auto auto auto;
}

.project-name-wrap {
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.project-name-link {
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
  text-decoration: none;
}

.project-name-link:hover {
  opacity: 0.8;
}

.project-name-link:active {
  opacity: 0.7;
}

.project-cover-link {
  margin-bottom: 40px;
}

.project-cover-link:hover {
  opacity: 0.8;
}

.project-cover-link:active {
  opacity: 0.7;
}

.project-overview-header {
  display: flex;
  height: 620px;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
 background-image: url("../../../public/img/landing/portfolio1-wide.svg"); 
  background-position: 50% 50%;
  background-size: cover;
  color: #fff;
}

.project-overview-header.cc-project-2-header {
 background-image: url("../../../public/img/landing/portfolio2-wide.svg"); 
  background-position: 50% 50%;
}

.project-overview-header.cc-project-3-header {
   background-image: url("../../../public/img/landing/portfolio3-wide.svg"); 
  background-size: cover;
}

.project-details-grid {
  margin-top: 100px;
  margin-bottom: 100px;
  align-items: start;
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template-areas: ". .";
  -ms-grid-columns: 1fr 80px 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.project-pics {
  margin-bottom: 120px;
}

.detail-image {
  width: 100%;
  margin-bottom: 30px;
}

.blog-detail-header-wrap {
  width: 70%;
  margin: 60px auto;
}

.detail-header-image {
  display: block;
  width: 100%;
  height: 620px;
  margin-bottom: 60px;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg");
  background-position: 0px 0px;
  background-size: cover;
}

.detail-header-image.w--current {
  margin-bottom: 60px;
}

.blog-list-wrap {
  margin-bottom: 100px;
}

.blog-item {
  display: block;
  margin-right: auto;
  margin-bottom: 80px;
  margin-left: auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.blog-preview-image {
  display: block;
  width: 100%;
  height: 620px;
  margin-bottom: 45px;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg");
  background-position: 0px 0px;
  background-size: cover;
  transition: opacity 600ms ease;
}

.blog-preview-image:hover {
  opacity: 0.8;
}

.blog-preview-image:active {
  opacity: 0.7;
}

.blog-summary-wrap {
  width: 70%;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  text-align: left;
}

.blog-heading-link {
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 46px;
  font-weight: 400;
  text-decoration: none;
}

.blog-heading-link:hover {
  opacity: 0.8;
}

.blog-heading-link:active {
  opacity: 0.7;
}

.contact-email-link {
  margin-bottom: 5px;
  opacity: 0.6;
}

.contact-email-link:hover {
  opacity: 1;
}

.contact-email-link:active {
  opacity: 0.8;
}

.protected-form {
  display: flex;
  flex-direction: column;
}

.protected-wrap {
  display: flex;
  padding-top: 90px;
  padding-bottom: 100px;
  justify-content: center;
}

.protected-heading {
  margin-bottom: 30px;
}

.utility-page-wrap {
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  padding: 30px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

._404-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1a1b1f;
}

._404-content-wrap {
  margin-bottom: 20px;
}

.home-content-wrap {
  margin-top: 80px;
  margin-bottom: 120px;
}

.home-section-wrap {
  width: 70%;
  margin-bottom: auto;
}

.section-heading {
  display: block;
  width: 50%;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-right: 5%;
  padding-left: 5%;
  float: right;
  flex-direction: column;
  align-items: flex-end;
  font-size: 45px;
  font-weight: 400;
  text-align: center;
}

.about-grid {
  margin-bottom: 5%;
  align-items: center;
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template-areas: ". .";
  -ms-grid-columns: 1fr 80px 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.about-grid.cc-about-2 {
  grid-template-areas: ". .";
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
}

.intro-text {
  width: 80%;
  margin-right: auto;
  margin-bottom: 35px;
  margin-left: auto;
}

.collection-wrap {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.work-heading {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 400;
}

.blog-heading {
  margin-bottom: 60px;
  text-align: center;
}

.blog-preview-wrap {
  width: 33.33%;
  padding-right: 45px;
  padding-bottom: 30px;
  padding-left: 15px;
}

.collection-list-wrapper {
  margin-bottom: 120px;
}

.business-article-heading {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
  text-decoration: none;
}

.business-article-heading:hover {
  opacity: 0.8;
}

.business-article-heading:active {
  opacity: 0.7;
}

._w-lightbox-caption {
  background-color: rgba(0, 0, 0, 0.6);
}

.text-block {
  position: static;
  float: right;
}

.div-block {
  display: flex;
  width: 70%;
  margin-top: 6%;
  margin-right: auto;
  margin-left: 30%;
  padding-right: 0%;
  padding-left: 0%;
  clear: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  font-size: 17px;
  text-align: center;
}

.text-block-2 {
  display: inline;
  width: 99px;
  font-family: Barlow, sans-serif;
  font-weight: 500;
}

.section-2 {
  display: flex;
  margin-top: auto;
  padding-top: 0px;
  justify-content: space-between;
}

.subscript {
  margin-right: auto;
}

.heading {
  display: block;
  overflow: visible;
  margin-top: 10%;
  margin-right: 0px;
  margin-bottom: auto;
  padding-right: 0px;
  padding-left: 0px;
  font-family: Barlow, sans-serif;
  font-size: 21px;
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
}

.div-block-2 {
  position: relative;
  top: 29px;
  right: 86px;
  overflow: visible;
  margin-top: 0px;
  margin-right: -57px;
  padding-top: 0px;
  padding-bottom: 0px;
  -o-object-fit: fill;
  object-fit: fill;
}

.div-block-3 {
  margin-bottom: 60px;
  margin-left: 34px;
  text-align: right;
}

.button-2 {
  display: inline-block;
  margin-top: 65px;
  margin-right: 46px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: center;
}

.button-2.texto-boton {
  display: inline-block;
  margin: auto;
  padding: 2%;
  border-radius: 0px;
  background-color: transparent;
  color: #4b92ce;
}

.text-span {
  color: #fff;
  font-size: 24px;
}

.block-quote {
  margin-top: auto;
  margin-right: auto;
  margin-bottom: 2%;
  padding: 0% 0px 0px 2%;
  font-family: Barlow, sans-serif;
  font-weight: 500;
  text-align: left;
}

.heading-2 {
  margin-top: 0%;
  margin-bottom: 0%;
  padding-top: 5%;
  font-size: 38px;
  font-style: italic;
  font-weight: 500;
  text-align: center;
}

.paragraph {
  margin-top: 0%;
  margin-right: 0px;
  margin-bottom: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  padding-left: 5%;
  font-size: 30px;
  text-align: center;
}

.body {
  margin-right: 0px;
  background-color: #212529;
  font-family: Barlow, sans-serif;
  color: #fff;
}

.columns {
  width: 100%;
  margin: 10% auto 0px;
  background-image: url("../../../public/img/landing/mandops4.jpg");
  background-position: 50% 50%;
  background-size: auto;
}

.container-2 {
  display: block;
  height: 30%;
  margin-top: 10%;
  margin-bottom: 5%;
  padding-right: 0%;
  padding-left: 0%;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-image: url("../../../public/img/landing/view-illuminated-neon-gaming-keyboard-setup-controller.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: scroll;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
  -o-object-fit: fill;
  object-fit: fill;
}

.paragraph-2 {
  text-align: right;
}

.paragraph-3 {
  width: 50%;
  margin: 2% auto 0%;
  float: none;
  clear: none;
  font-size: 35px;
  text-align: center;
}

.heading-3 {
  overflow: visible;
  width: 60%;
  margin-top: 0%;
  margin-bottom: 5%;
  padding-right: 0%;
  padding-left: 0%;
  float: left;
  font-size: 22px;
  text-align: center;
  -o-object-fit: fill;
  object-fit: fill;
}

.heading-4 {
  margin-top: 0%;
  margin-bottom: 0%;
  text-align: center;
}

.button-3 {
  position: static;
  display: block;
  width: 50%;
  margin-right: 25%;
  padding: 2%;
  float: right;
  clear: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  font-family: Barlow, sans-serif;
  text-align: center;
}

.button-4 {
  margin-right: 0px;
  margin-left: 27%;
  padding: 2% 5%;
  float: left;
  text-align: center;
}

.paragraph-4 {
  width: 100%;
  margin-bottom: 10%;
  padding-right: 4%;
  padding-left: 5%;
  font-family: Barlow, sans-serif;
  text-align: center;
}

.heading-5 {
  margin-top: 0%;
  margin-bottom: 0%;
  font-family: Barlow, sans-serif;
  text-align: center;
}

.paragraph-5 {
  margin-bottom: 10%;
  padding-right: 3%;
  padding-left: 2%;
  font-family: Barlow, sans-serif;
  font-size: 20px;
  text-align: center;
}

.text-block-3 {
  margin-top: 5%;
  font-family: Barlow, sans-serif;
  font-size: 20px;
  text-align: center;
}

.image {
  position: absolute;
  right: 0px;
  display: block;
}

.image-2 {
  display: inline-block;
  margin-top: 7%;
  border-radius: 120px;
}

.div-block-4 {
  text-align: center;
}

.heading-6 {
  margin-top: 3%;
  margin-bottom: 0%;
  font-size: 30px;
}

.image-3 {
  display: block;
  width: 55%;
  margin-top: 7%;
  margin-right: 22%;
  margin-left: auto;
  float: right;
  clear: none;
  border-radius: 200px;
  -o-object-fit: fill;
  object-fit: fill;
}

.heading-7 {
  margin-top: 5%;
  margin-bottom: 0%;
  font-size: 30px;
  text-align: center;
}

.heading-8 {
  margin-top: 3%;
  margin-bottom: 0%;
  font-size: 30px;
  text-align: center;
}

.image-4 {
  margin-top: 0%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5%;
  border-radius: 200px;
}

.heading-9 {
  margin-top: 5%;
  margin-bottom: 3%;
  font-family: Barlow, sans-serif;
  color: #eb601b;
  text-align: center;
}

.paragraph-6 {
  margin-bottom: 2%;
  text-align: center;
}

.button-5 {
  display: flex;
  width: 30%;
  margin-right: auto;
  margin-left: auto;
  padding: 3% 5%;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: flex-end;
  align-content: center;
}

.button-6 {
  display: flex;
  width: 30%;
  margin-right: auto;
  margin-left: auto;
  padding: 3% 5%;
  justify-content: center;
  align-items: flex-end;
  font-family: Barlow, sans-serif;
}

.columns-2 {
  margin-top: 3%;
  margin-bottom: 5%;
  margin-left: 10%;
  background-color: transparent;
}

.image-5 {
  width: 100px;
  float: none;
  border-radius: 220px;
  background-color: transparent;
  box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
  filter: blur(10px);
}

.paragraph-7 {
  margin-bottom: 3%;
  text-align: center;
}

.heading-10 {
  margin-top: 10%;
  margin-bottom: 0px;
  background-color: transparent;
  font-size: 22px;
  text-align: center;
}

.div-block-5 {
  margin-bottom: 3%;
  padding-bottom: 1%;
  background-color: transparent;
  line-height: 40px;
}

.image-6 {
  display: block;
  margin: 1% 0% auto 7%;
  float: left;
}

.heading-11 {
  width: 25%;
  margin: 0% 0% 0px 0px;
  float: none;
  color: #fff;
}

.block-quote-2 {
  width: 70%;
  margin: 2% 11% 5% auto;
  padding: 0% 0px 0px 1%;
  float: right;
  color: #fff;
  font-size: 16px;
  text-align: justify;
}

.slide {
  margin-bottom: 0px;
  padding-bottom: 0px;
  background-color: #2d2f32;
  color: #000;
}

.dropdown-toggle {
  width: 100%;
}

.text {
  margin-bottom: 0px;
  color: #000;
  font-size: 12px;
}

.text.white {
  color: #fff;
}

.arrow {
  color: #000;
}

.arrow.white {
  color: #fff;
}

.accordion-wrap {
  display: flex;
  height: 500px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.accordian {
  display: flex;
  overflow: hidden;
  width: 450px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  border-style: solid;
  border-width: 1px 2px;
  border-color: #000;
  border-radius: 0px;
  background-color: transparent;
}

.accordian.white {
  border-width: 1px 2px;
  border-color: #fff;
}

.inside {
  padding: 20px 35px;
  color: #fff;
}

.dropdown-2 {
  width: 100%;
}

.textblock {
  color: #000;
}

.textblock.white {
  color: #fff;
}

.toggle {
  display: block;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.toggle.white {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.list {
  position: relative;
  display: block;
  overflow: hidden;
  height: 0px;
  background-color: transparent;
}

.div-block-6 {
  margin-top: -15px;
  margin-right: -503px;
  margin-left: -213px;
  background-color: #eb601d;
}

.div-block-7 {
  margin-top: 0px;
  margin-right: -60px;
  margin-left: -60px;
  border-radius: 220px;
  background-color: #4b92ce;
}

.image-7 {
  margin-left: 29px;
}

.paragraph-8 {
  margin-top: 7px;
  margin-bottom: 28px;
  color: #fff;
  text-align: left;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.paragraph-9 {
  margin-right: -70px;
  margin-left: 26px;
  padding-left: 0px;
  color: #fff;
  text-align: left;
}

.image-8 {
  margin-top: -20px;
  margin-bottom: 10px;
  padding-top: 0px;
}

.image-9 {
  margin-left: 42px;
}

.text-block-4 {
  width: 20%;
  margin-left: 0px;
  float: left;
  color: #fff;
  font-size: 15px;
  text-align: left;
}

.superscript {
  margin-left: -31px;
}

.columns-3 {
  width: 100%;
  margin-bottom: 12px;
}

.image-10 {
  margin-left: 30px;
}

.image-11 {
  margin-left: 23px;
}

.image-12 {
  margin-top: -50px;
  margin-bottom: 0px;
  margin-left: 243px;
}

.background {
  background-color: transparent;
}

.texto-boton {
  width: 100%;
  padding-left: 0%;
  font-family: Barlow, sans-serif;
  font-weight: 500;
  -o-object-fit: fill;
  object-fit: fill;
}

.column {
  margin-top: 5%;
  margin-bottom: 0%;
  padding: 0px;
}

.bold-text {
  display: block;
  overflow: visible;
  padding-right: 25%;
  font-size: 28px;
  text-align: left;
  -o-object-fit: fill;
  object-fit: fill;
}

.column-2 {
  margin-top: 15%;
  margin-bottom: 0%;
  padding-left: 10%;
}

.bold-text-2 {
  display: block;
  width: 100%;
  margin-top: 0%;
  margin-right: 25%;
  margin-bottom: 0%;
  padding-right: 5%;
  font-size: 56px;
}

.heading-12 {
  width: 80%;
  margin-top: 10%;
  margin-bottom: auto;
  padding-right: 0%;
}

.text-block-5 {
  font-family: Barlow, sans-serif;
  font-size: 22px;
}

.italic-text {
  font-family: Barlow, sans-serif;
  font-size: 63px;
}

.image-13 {
  display: block;
  width: 200px;
  height: 200px;
  margin: -10% 0% 0% -10%;
  border-radius: 220px;
  font-size: 50px;
  line-height: 50px;
  text-align: right;
}

.image-14 {
  width: 220px;
  height: 220px;
  margin-top: 10%;
  margin-right: 0%;
  margin-left: 15%;
  border-radius: 260px;
}

.link {
  width: 20%;
  margin: 1% auto 0%;
  float: none;
  color: #4b92ce;
  font-size: 24px;
  text-align: center;
  text-decoration: none;
}

.columns-4 {
  width: 100%;
  margin-top: 0%;
  margin-bottom: 0px;
}

.column-3 {
  padding-right: 2%;
  padding-left: 2%;
}

.column-4 {
  display: block;
  padding-right: 0%;
  padding-left: 0%;
  border-radius: 60px;
  background-color: transparent;
  background-image: linear-gradient(258deg, #3898ec 31%, transparent 100%, transparent);
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.column-5 {
  height: auto;
  padding-right: 0px;
  padding-left: 0%;
  border-radius: 60px;
  background-image: linear-gradient(268deg, #eb601d 24%, transparent);
}

.italic-text-2 {
  padding-right: 10%;
  padding-left: 10%;
  font-size: 20px;
  text-align: center;
}

.button-7 {
  padding: 3% 5%;
}

.section-3 {
  margin: 10% auto;
}

.image-15 {
  width: 270px;
  height: 270px;
  margin-right: 5%;
  margin-left: 5%;
  padding-right: 0px;
  float: right;
  border-radius: 200px;
}

.column-6 {
  padding-right: 0%;
  padding-left: 0%;
}

.column-7 {
  padding-right: 0%;
  padding-left: 0%;
}

.column-8 {
  padding-right: 0%;
  padding-left: 0%;
}

.column-9 {
  padding-right: 0%;
  padding-left: 0%;
}

.slide-nav {
  margin-top: 0%;
  padding-top: 1%;
  padding-bottom: 4%;
  border-radius: 0px;
  background-color: #2d2f32;
  color: #000;
}

.slide-2 {
  background-color: #2d2f32;
}

.columns-5 {
  background-color: #2d2f32;
}

.slide-3 {
  margin-top: auto;
  background-color: #2d2f32;
}

.button-8 {
  margin-left: 38%;
  -ms-grid-row-align: center;
  align-self: center;
  background-color: transparent;
  font-family: Barlow, sans-serif;
  color: #fff;
  font-size: 18px;
  text-align: center;
}

.image-16 {
  width: 200px;
  height: 200px;
  margin: -10% 0% 0% 10%;
  float: left;
  border-radius: 200px;
}

.italic-text-3 {
  color: #3898ec;
}

.link-2 {
  color: #eb601d;
  text-align: center;
}

.link-2.link-4 {
  text-decoration: none;
}

.link-3 {
  margin-top: 0%;
  color: #eb601d;
}

.link-4 {
  color: #eb601d;
  text-align: center;
  text-decoration: none;
}

.link-4:hover {
  color: #fff;
}

.italic-text-4 {
  display: inline;
  height: 250px;
  padding-right: 0%;
  padding-left: 0%;
  font-size: 29px;
}

.div-block-8 {
  width: 80%;
  float: right;
}

.div-block-9 {
  width: 40%;
  margin-left: 10%;
  padding-left: 11%;
}

.section-4 {
  display: block;
  padding: 40px 55px 110px;
  justify-content: center;
  align-items: center;
  background-color: #252525;
}

.section-4._3 {
  display: block;
  height: 100vh;
  padding-top: 220px;
  padding-right: 0px;
  padding-left: 0px;
  background-color: #555;
}

.section-5 {
  margin-top: 5%;
}

.brix---btn-circle-accordion-btn {
  display: flex;
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #eff0f6;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(20, 20, 43, 0.08);
  transition: transform 300ms ease;
  color: #000;
  font-size: 22px;
  text-decoration: none;
  transform-style: preserve-3d;
}

.brix---btn-circle-accordion-btn:hover {
  border-color: #eff0f6;
  background-color: #fff;
  transform: scale3d(0.94, 0.94, 1.01);
}

.brix---accordion-right-side {
  margin-right: 0px;
  margin-left: 24px;
}

.brix---paragraph-default {
  margin-bottom: 0px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
}

.brix---color-neutral-600 {
  color: #000;
}

.brix---accordion-spacer {
  min-height: 16px;
}

.brix---acordion-body {
  overflow: hidden;
  width: 100%;
}

.brix---accordion-title {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
}

.brix---color-neutral-800 {
  color: #211f54;
}

.brix---accordion-header {
  display: flex;
  align-items: center;
}

.brix---accordion-content-wrapper-v2 {
  display: flex;
  max-width: 730px;
  flex-direction: column;
  justify-content: center;
}

.brix---accordion-item-wrapper-v3-last {
  display: flex;
  overflow: visible;
  width: 100%;
  margin-bottom: -1px;
  padding: 40px 0px;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px 0px;
  border-color: #eff0f6;
  border-radius: 0px;
  background-color: #fff;
  box-shadow: none;
  transition: transform 300ms ease;
  cursor: pointer;
}

.brix---accordion-item-wrapper-v3-last:hover {
  transform: translate(8px, 0px);
}

.brix---accordion-item-wrapper-v3 {
  display: flex;
  overflow: visible;
  width: 100%;
  margin-bottom: -1px;
  padding: 40px 0px;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px 0px;
  border-color: #eff0f6;
  border-radius: 0px;
  background-color: #fff;
  box-shadow: none;
  transition: transform 300ms ease;
  cursor: pointer;
}

.brix---accordion-item-wrapper-v3:hover {
  transform: translate(8px, 0px);
}

.brix---accordion-item-wrapper-v3-first {
  display: flex;
  overflow: visible;
  width: 100%;
  margin-bottom: -1px;
  padding: 0px 0px 40px;
  justify-content: space-between;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-color: #eff0f6;
  border-radius: 0px;
  background-color: #fff;
  box-shadow: none;
  transition: transform 300ms ease;
  cursor: pointer;
}

.brix---accordion-item-wrapper-v3-first:hover {
  transform: translate(8px, 0px);
}

.brix---grid-1-column-gap-row-0 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.brix---accordion-v3-card {
  padding: 88px 74px;
  border-style: solid;
  border-width: 1px;
  border-color: #eff0f6;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(20, 20, 43, 0.06);
}

.brix---inner-container-1012px-center {
  max-width: 1012px;
  margin-right: auto;
  margin-left: auto;
}

.brix---heading-h2-size {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #eff0f6;
  font-size: 38px;
  line-height: 50px;
  font-weight: 700;
}

.brix---mg-bottom-16px {
  margin-bottom: 16px;
}

.brix---text-center {
  text-align: center;
}

.brix---inner-container-574px---center {
  max-width: 574px;
  margin-right: auto;
  margin-left: auto;
}

.brix---mg-bottom-48px {
  margin: 15% 5% 0px 10%;
  float: left;
}

.brix---container-default {
  max-width: 1218px;
  padding-right: 24px;
  padding-left: 24px;
}

.brix---section {
  margin-bottom: -6%;
  padding-top: 0%;
  padding-bottom: 5%;
  background-image: url("../../../public/img/landing/videojuego-ganador-amigos-vista-lateral.jpg");
  background-position: 0px 0px;
  background-size: cover;
  color: #fff;
}

.brix---accordion-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brix---accordion-btn-horizontal-line {
  position: absolute;
  width: 18px;
  height: 2px;
  border-radius: 20px;
  background-color: #211f54;
}

.brix---accordion-btn-vertical-line {
  position: absolute;
  width: 18px;
  height: 2px;
  border-radius: 20px;
  background-color: #211f54;
  transform: rotate(90deg);
}

.brix---accordion-side {
  margin-right: 24px;
}

.brix---accordion-item-wrapper {
  display: flex;
  width: 100%;
  padding: 48px 64px;
  border-style: solid;
  border-width: 1px;
  border-color: #eff0f6;
  border-radius: 24px;
  background-color: rgba(33, 37, 41, 0.66);
  box-shadow: 0 2px 12px 0 rgba(20, 20, 43, 0.08);
  transition: transform 300ms ease;
  color: #fff;
  cursor: pointer;
  transform-style: preserve-3d;
}

.brix---accordion-item-wrapper:hover {
  transform: scale3d(0.96, 0.96, 1.01);
}

.brix---grid-1-column-gap-row-32px {
  display: -ms-grid;
  display: grid;
  float: none;
  grid-auto-columns: 1fr;
  grid-row-gap: 32px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  text-align: justify;
}

.brix---inner-container-856---center {
  max-width: 856px;
  margin-top: 5%;
  margin-right: auto;
  margin-left: auto;
}

.brix---heading-h2-size-2 {
  margin-top: 10%;
  margin-bottom: 0px;
  float: none;
  font-family: Barlow, sans-serif;
  color: #eff0f6;
  font-size: 54px;
  line-height: 55px;
  font-weight: 700;
}

.uui-testimonial06_slide-nav {
  height: 1.75rem;
  padding-top: 1%;
  font-size: 0.5rem;
}

.uui-button-icon {
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uui-testimonial06_arrow {
  display: flex;
  width: 3.5rem;
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #eaecf0;
  border-radius: 100%;
  background-color: #fff;
  transition: all 200ms ease;
  color: #667085;
}

.uui-testimonial06_arrow:hover {
  background-color: #f9fafb;
}

.uui-testimonial06_arrow.hide-mobile-landscape {
  margin-top: 10%;
}

.uui-testimonial06_logo {
  max-height: 3rem;
}

.uui-testimonial06_divider {
  width: 1px;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  background-color: #eaecf0;
}

.uui-text-size-medium {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #475467;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: normal;
}

.uui-heading-tiny {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: Barlow, sans-serif;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: normal;
}

.uui-testimonial06_client-image {
  width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.uui-testimonial06_client-image-wrapper {
  margin-right: 1rem;
}

.uui-testimonial06_client {
  display: flex;
  margin-bottom: 2%;
  align-items: center;
  text-align: left;
}

.uui-heading-medium {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #101828;
  font-size: 2.25rem;
  line-height: 1.3;
  font-weight: 600;
}

.uui-heading-medium.text-weight-medium {
  font-family: Barlow, sans-serif;
  color: #fff;
  font-size: 1.7rem;
  font-weight: 500;
}

.uui-testimonial06_rating-icon {
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
  justify-content: center;
  align-items: center;
  color: #fec84b;
}

.uui-testimonial06_rating-wrapper {
  display: flex;
  padding-top: 1%;
  align-items: center;
}

.uui-testimonial06_content {
  display: flex;
  max-width: 64rem;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  align-items: center;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  text-align: center;
}

.uui-testimonial06_slide {
  padding-right: 4rem;
  padding-left: 4rem;
}

.uui-testimonial06_mask.testimonios {
  border-radius: 60px;
  background-color: rgba(68, 67, 67, 0.05);
  background-image: linear-gradient(1deg, rgba(68, 67, 67, 0.03), #212529);
}

.uui-testimonial06_component {
  height: auto;
  padding-bottom: 4rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.uui-padding-vertical-xhuge {
  padding-top: 5%;
  padding-bottom: 5%;
}

.uui-container-large {
  width: 100%;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
}

.uui-page-padding {
  padding-right: 2rem;
  padding-left: 2rem;
  background-image: url("../../../public/img/landing/configuracion-juegos-controlador-auriculares-2.jpg");
  background-position: 0px 0px;
  background-size: cover;
}

.f-avatar-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.f-avatar-large {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 72px;
  height: 72px;
  min-height: 72px;
  min-width: 72px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #f3f5fb;
}

.f-avatar-regular {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #f3f5fb;
}

.f-avatar-small {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 32px;
  height: 32px;
  min-height: 32px;
  min-width: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #f3f5fd;
}

.brix---paragraph-link {
  transition: color 300ms ease;
  color: #4a3aff;
  text-decoration: underline;
}

.brix---paragraph-link:hover {
  color: #281ca5;
}

.brix---paragraph-default-2 {
  width: 100%;
  margin-bottom: 0px;
  margin-left: -3%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #fff;
  font-size: 12px;
  line-height: 30px;
  text-align: left;
}

.brix---color-neutral-801 {
  display: flex;
  width: 60%;
  color: #6e7191;
}

.brix---text-right---t-center {
  text-align: right;
}

.brix---text-left---t-center {
  text-align: left;
}

.brix---grid-footer-v11 {
  align-items: center;
  -ms-grid-columns: 0.5fr 1fr;
  grid-template-columns: 0.5fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: transparent;
}

.brix---footer-small {
  padding-top: 3%;
  padding-bottom: 3%;
}

.brix---container-default-2 {
  max-width: 100%;
  margin-top: -1.5%;
  padding-right: 1%;
  padding-left: 2%;
  background-color: #eb601d;
}

.brix---footer-wrapper {
  border-top: 1px solid #eff0f6;
  background-color: #fff;
}

.image-17 {
  width: 25px;
}

.image-18 {
  height: 27px;
  text-align: center;
}

.image-19 {
  width: 81px;
  height: 25px;
}

.brix---paragraph-link-2 {
  transition: color 300ms ease;
  color: #4a3aff;
  text-decoration: underline;
}

.brix---paragraph-link-2:hover {
  color: #281ca5;
}

.brix---mg-bottom-0 {
  margin-bottom: 0px;
}

.brix---footer-logo {
  padding-right: 0%;
  float: left;
  transition: transform 300ms ease;
  text-align: left;
  transform-style: preserve-3d;
}

.brix---footer-logo:hover {
  transform: scale3d(0.97, 0.97, 1.01);
}

.brix---footer-logo-wrapper {
  float: left;
}

.brix---grid-footer-logo---paragraph {
  align-items: center;
  -ms-grid-columns: 0.3fr 1fr;
  grid-template-columns: 0.3fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.brix---footer-bottom-v4 {
  height: 62%;
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid #eff0f6;
  color: #6e7191;
  font-size: 18px;
  line-height: 30px;
  text-align: right;
}

.brix---container-default-3 {
  max-width: 1218px;
  padding-right: 24px;
  padding-left: 24px;
}

.brix---footer-wrapper-2 {
  height: 80%;
  border-top: 0px none transparent;
  background-color: #4b92ce;
}

.columns-6 {
  margin-right: auto;
  margin-left: auto;
}

.link-5 {
  width: 20%;
  float: left;
}

.link-6 {
  width: 22%;
  float: left;
}

.link-7 {
  width: 20%;
  float: left;
}

.link-8 {
  width: 100%;
}

.columns-7 {
  margin-right: 0px;
  margin-left: auto;
}

.f-navigation-button {
  display: flex !important;
    padding: 2% 10%;
    justify-content: center;
    align-items: center;
    border-radius: 48px;
    background-color: #eb601d;
    outline-color: transparent;
    outline-offset: 0px;
    outline-style: solid;
    outline-width: 2px;
    transition: all 200ms ease;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.02em;
    text-decoration: none;
    width: 137px;
    height: 36px;
    color: white !important;
    font-weight: 800;
}

.f-navigation-button:hover {
  background-color: #fff;
  color: #000;
}

.f-navigation-content {
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
}

  .f-navigation-link {
    margin-left: 15%;
    padding: 3% 5%;
    border-radius: 50px;
    background-color: transparent;
    transition: all 350ms ease;
    color: #fff !important;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -0.01em;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 700;
}

.f-navigation-link:hover {
  background-color: #3898ec;
  color: #fff;
}

.f-navigation-link.w--current {
  color: #642eff;
}

.f-navigation-menu {
  position: static;
  display: flex;
  align-items: center;
  grid-column-gap: 6px;
}

.f-logo {
  width: 124px;
}

.f-navigation-container {
  display: flex;
  width: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;
  align-items: center;
}

  .f-navigation {
    position: fixed;
    top: auto;
    z-index: 5000;
    display: flex;
    width: 100%;
    min-height: 88px;
    margin-top: 0%;
    margin-bottom: 0%;
    padding: 24px 5%;
    align-items: center;
    background-color: #000;
    opacity: 1;
    font-family: Barlow;
}

.f-navigation-button-copy {
  display: flex;
  width: 250px;
  min-width: 0px;
  margin-right: 3%;
  padding: 2% 5%;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  background-color: #212529;
  outline-color: transparent;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  transition: all 200ms ease;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.02em;
  text-decoration: none;
}

.f-navigation-button-copy:hover {
  background-color: #fff;
  color: #000;
}

.streamers {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 60px;
  background-color: #212529;
  background-image: linear-gradient(180deg, rgba(66, 66, 66, 0.47), #212529);
}

.heading-13 {
  text-align: center;
}

.descubre {
  width: 100%;
  min-width: 0px;
  padding-left: 5%;
  float: none;
  text-decoration: none;
}

.section-6 {
  height: 100%;
  margin-bottom: 2%;
  padding-bottom: 3%;
  background-image: url("../../../public/img/landing/mandops4.jpg");
  background-position: 55% 50%;
  background-size: cover;
}

.bold-text-4 {
  display: block;
  margin-top: 5%;
  padding-top: 5%;
}

.paragraph-10 {
  margin-bottom: 0px;
  padding-right: 10%;
  padding-left: 10%;
  float: none;
  font-family: Barlow, sans-serif;
  color: #eb601b;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
}

.bold-text-5 {
  margin-left: auto;
  float: left;
  color: #3898ec;
  text-align: center;
}

.columns-8 {
  margin-bottom: 10%;
}

.columns-9 {
  position: relative;
  left: 10%;
  display: block;
  width: 80%;
  float: none;
  justify-content: space-between;
  align-items: center;
}

.bold-text-6 {
  display: block;
  font-size: 30px;
}

.bold-text-7 {
  font-size: 30px;
}

.italic-text-5 {
  width: 100%;
  height: 250px;
  font-size: 30px;
}

.bold-text-8 {
  float: left;
  font-size: 30px;
}

.f-avatar-image-2 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.f-avatar-regular-2 {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #f3f5fb;
}

.collage {
  position: relative;
  width: 60%;
  height: 45vh;
  min-height: 200px;
  margin-right: 5px;
  margin-left: 5px;
  float: left;
  background-image: url("../../../public/img/landing/jugando.jpg"), url("../../../public/img/landing/mesaçmando.jpg");
  background-position: 50% 50%, 0px 0px;
  background-size: cover, cover;
  line-height: 50px;
}

.collage.coll1 {
  width: 35%;
  height: 60vh;
  min-height: 200px;
  margin-top: -14vh;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 0px;
  padding-left: 0px;
  background-image: url("../../../public/img/landing/fotosplat1.png"),
    url("../../../public/img/landing/medium-shot-smiley-people-playing-videogame.jpg"),
    url("../../../public/img/landing/mesaçmando.jpg");
  background-position: 30% 50%, 0px 0px, 0px 0px;
  background-size: cover, cover, auto;
}

.collage.coll1.coll7 {
  width: 100%;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg"),
    url("../../../public/img/landing/medium-shot-smiley-people-playing-videogame.jpg"),
    url("../../../public/img/landing/mesaçmando.jpg");
  background-position: 0px 0px, 0px 0px, 0px 0px;
  background-size: auto, cover, auto;
}

.collage.coll2 {
  width: 60%;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 5px;
  background-image: url("../../../public/img/landing/fotosplat3.png"), url("../../../public/img/landing/mesaçmando.jpg");
  background-position: 100% 50%, 0px 0px;
  background-size: cover, auto;
}

.collage.coll3 {
  width: 35%;
  height: 30vh;
  margin-right: 5px;
  margin-left: 5px;
  background-image: url("../../../public/img/landing/fotosplat2.png"),
    url("../../../public/img/landing/view-illuminated-neon-gaming-keyboard-setup-controller.jpg"),
    url("../../../public/img/landing/mesaçmando.jpg");
  background-position: 0% 50%, 0px 0px, 0px 0px;
  background-size: auto, cover, auto;
}

.italic-text-6 {
  display: block;
  font-size: 40px;
}

.section-7 {
  margin-top: 5%;
  margin-bottom: 8%;
}

.italic-text-7 {
  margin-right: auto;
  margin-left: auto;
  padding-right: 0%;
  font-size: 40px;
}

.link-9 {
  width: 40%;
  font-size: 19px;
}

.bold-text-9 {
  font-size: 45px;
}

.div-block-10 {
  width: 25%;
  height: 6%;
  margin-right: auto;
  margin-left: 20%;
  float: left;
  border-radius: 30px;
  background-image: linear-gradient(272deg, #4b92ce 27%, transparent);
}

.div-block-11 {
  width: 25%;
  height: 6%;
  margin-right: 20%;
  margin-left: auto;
  float: right;
  border-radius: 30px;
  background-image: linear-gradient(267deg, #eb601d 28%, transparent 99%);
}

.div-block-12 {
  height: 500px;
  margin-top: 0px;
  float: none;
  background-image: url("../../../public/img/landing/fotosplat5.png");
  background-position: 50% 100%;
  background-size: cover;
}

.heading-14 {
  display: block;
  width: 50%;
  margin: auto;
  padding-top: 13%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  font-style: italic;
  text-align: center;
}

.bold-text-10 {
  font-size: 55px;
}

.column-10 {
  padding-right: 5%;
}

.column-11 {
  padding-left: 5%;
}

.heading-15 {
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.link-10 {
  width: 50%;
  margin-right: auto;
  margin-bottom: 2%;
  margin-left: auto;
  color: #4b92ce;
  font-size: 26px;
  text-align: center;
  text-decoration: none;
}

.link-11 {
  width: 65%;
  margin-top: 3%;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  font-size: 25px;
  text-align: left;
  text-decoration: none;
}

.section-8 {
  display: block;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .section.cc-cta {
    padding-right: 0px;
    padding-left: 0px;
  }

  .styleguide-block {
    text-align: center;
  }

  .button {
    justify-content: center;
  }

  .button.cc-contact-us {
    display: none;
  }

  .heading-jumbo {
    font-size: 56px;
    line-height: 70px;
  }

  .logo-link {
    font-family: Barlow, sans-serif;
  }

  .logo-link.w--current {
    flex: 1;
  }

  .menu-icon {
    display: block;
  }

  .menu {
    position: static;
    margin-left: auto;
    background-color: #edf0f3;
    background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg");
    background-position: 0px 0px;
    background-size: auto;
    text-align: left;
  }

  .navigation-wrap {
    background-color: #fff;
  }

  .navigation {
    padding: 25px 30px;
  }

  .te {
    padding: 15px 30px;
    transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    text-align: center;
  }

  .te:hover {
    background-color: #f7f8f9;
  }

  .te:active {
    background-color: #eef0f3;
  }

  .menu-button {
    padding: 0px;
  }

  .menu-button.w--open {
    background-color: transparent;
  }

  .navigation-items {
    background-color: #fff;
  }

  .cta-text {
    width: auto;
  }

  .cta-wrap {
    width: auto;
    padding: 80px 50px 90px;
  }

  .our-services-grid {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-template-areas:
      ". ."
      ". ."
      ". .";
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
    text-align: center;
  }

  .service-icon {
    display: inline-block;
  }

  .contact-form-grid {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-row-gap: 50px;
    grid-template-areas:
      "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 50px auto;
    grid-template-rows: auto auto;
    text-align: center;
  }

  .get-in-touch-form {
    text-align: left;
  }

  .our-contacts {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-template-areas:
      "."
      "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .team-members {
    grid-template-areas:
      ". ."
      ". ."
      ". .";
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .team-member-title-wrap {
    margin-bottom: 30px;
  }

  .project-details-grid {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-row-gap: 50px;
    grid-template-areas:
      "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 50px auto;
    grid-template-rows: auto auto;
    text-align: center;
  }

  .blog-detail-header-wrap {
    width: 70%;
  }

  .detail-header-image {
    height: 460px;
  }

  .blog-item {
    width: 80%;
  }

  .blog-preview-image {
    height: 460px;
  }

  .blog-summary-wrap {
    width: 100%;
  }

  .about-grid {
    grid-row-gap: 50px;
    grid-template-areas:
      "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 50px auto;
    grid-template-rows: auto auto;
    text-align: center;
  }

  .about-grid.cc-about-2 {
    grid-template-areas:
      "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .intro-text {
    width: auto;
  }

  .collection-wrap {
    margin-right: 0px;
    margin-left: 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  .blog-preview-wrap {
    width: 80%;
    padding-right: 15px;
    text-align: center;
  }

  .brix---accordion-spacer {
    min-height: 12px;
  }

  .brix---accordion-item-wrapper-v3-last {
    margin-bottom: 0px;
    padding-right: 40px;
    padding-bottom: 0px;
    padding-left: 40px;
    border-bottom-width: 0px;
  }

  .brix---accordion-item-wrapper-v3-last:hover {
    transform: translate(6px, 0px);
  }

  .brix---accordion-item-wrapper-v3 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .brix---accordion-item-wrapper-v3:hover {
    transform: translate(6px, 0px);
  }

  .brix---accordion-item-wrapper-v3-first {
    padding-right: 40px;
    padding-left: 40px;
  }

  .brix---accordion-item-wrapper-v3-first:hover {
    transform: translate(6px, 0px);
  }

  .brix---accordion-v3-card {
    padding: 64px 48px;
  }

  .brix---heading-h2-size {
    font-size: 30px;
    line-height: 46px;
  }

  .brix---mg-bottom-48px {
    margin-bottom: 40px;
  }

  .brix---section {
    padding-top: 118px;
    padding-bottom: 118px;
  }

  .brix---accordion-item-wrapper {
    padding-right: 40px;
    padding-left: 40px;
  }

  .brix---heading-h2-size-2 {
    font-size: 30px;
    line-height: 46px;
  }

  .uui-padding-vertical-xhuge {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .brix---text-right---t-center {
    text-align: center;
  }

  .brix---text-left---t-center {
    text-align: center;
  }

  .brix---grid-footer-v11 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .brix---footer-small {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .brix---footer-bottom-v4 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .f-navigation-menu-button {
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #f3f5fb;
    border-radius: 8px;
    color: #393b6a;
  }

  .f-navigation-menu-button.w--open {
    border-color: #fff;
    background-color: #160042;
  }

  .f-navigation-content {
    display: flex;
    width: auto;
    align-items: center;
  }

  .f-navigation-link {
    width: 100%;
  }

  .f-navigation-menu {
    position: absolute;
    padding: 12px;
    background-color: #fff;
    color: #393b6a;
  }

  .f-navigation-menu.w--open {
    border-color: #fff;
    background-color: #282556;
  }

  .f-navigation-logo-link {
    margin-right: 0px;
  }

  .f-navigation {
    position: relative;
  }

  .icon {
    color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .section {
    margin-right: 15px;
    margin-left: 15px;
  }

  .section.cc-cta {
    padding: 15px;
  }

  .section.cc-home-wrap {
    margin-right: 15px;
    margin-left: 15px;
  }

  .section.cc-store-home-wrap {
    margin-right: 15px;
    margin-left: 15px;
  }

  .container {
    text-align: center;
  }

  .paragraph-bigger {
    font-size: 16px;
    line-height: 28px;
  }

  .rich-text {
    width: 90%;
    max-width: 470px;
    text-align: left;
  }

  .heading-jumbo {
    font-size: 50px;
    line-height: 64px;
  }

  .heading-jumbo-small {
    font-size: 30px;
    line-height: 52px;
  }

  .logo-link {
    padding-left: 0px;
  }

  .navigation {
    padding: 20px 30px;
  }

  .cta-wrap {
    padding-right: 30px;
    padding-left: 30px;
  }

  .intro-content {
    width: 80%;
  }

  .our-services-grid {
    grid-row-gap: 60px;
    grid-template-areas:
      "."
      "."
      "."
      "."
      "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 60px auto 60px auto 60px auto 60px auto 60px auto;
    grid-template-rows: auto auto auto auto auto auto;
  }

  .status-message.cc-no-data {
    width: 80%;
    max-width: 470px;
  }

  .contact-form-wrap {
    padding: 30px;
  }

  .text-field.cc-contact-field {
    text-align: left;
  }

  .text-field.cc-textarea {
    text-align: left;
  }

  .team-pic {
    height: 300px;
    margin-bottom: 30px;
  }

  .team-member-name {
    font-size: 16px;
    line-height: 28px;
  }

  .project-name-wrap {
    margin-bottom: 30px;
  }

  .project-name-link {
    font-size: 16px;
    line-height: 28px;
  }

  .detail-image {
    margin-bottom: 15px;
  }

  .blog-detail-header-wrap {
    width: 90%;
    max-width: 470px;
    text-align: left;
  }

  .blog-item {
    width: 90%;
    max-width: 470px;
  }

  .blog-summary-wrap {
    text-align: center;
  }

  .utility-page-wrap {
    padding: 15px;
  }

  ._404-wrap {
    padding: 30px;
  }

  .section-4 {
    padding-right: 25px;
    padding-left: 25px;
    background-size: auto, auto;
  }

  .brix---btn-circle-accordion-btn {
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    font-size: 22px;
    line-height: 24px;
  }

  .brix---accordion-right-side {
    margin-right: 16px;
    margin-left: 16px;
  }

  .brix---accordion-title {
    font-size: 20px;
    line-height: 30px;
  }

  .brix---accordion-item-wrapper-v3-last {
    padding: 36px 0px;
  }

  .brix---accordion-item-wrapper-v3 {
    padding: 36px 0px;
  }

  .brix---accordion-item-wrapper-v3-first {
    padding: 36px 0px;
  }

  .brix---accordion-v3-card {
    padding: 40px 32px;
  }

  .brix---heading-h2-size {
    font-size: 28px;
    line-height: 40px;
  }

  .brix---mg-bottom-16px {
    margin-bottom: 8px;
  }

  .brix---mg-bottom-48px {
    margin-bottom: 32px;
  }

  .brix---section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .brix---accordion-side {
    margin-right: 16px;
  }

  .brix---accordion-item-wrapper {
    padding: 40px 24px;
  }

  .brix---heading-h2-size-2 {
    font-size: 28px;
    line-height: 40px;
  }

  .uui-testimonial06_arrow:hover {
    background-color: #fff;
  }

  .hide-mobile-landscape {
    display: none;
  }

  .uui-testimonial06_logo {
    max-height: 2.5rem;
  }

  .uui-testimonial06_divider {
    display: none;
  }

  .uui-testimonial06_client-info {
    margin-bottom: 1rem;
  }

  .uui-testimonial06_client-image-wrapper {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }

  .uui-testimonial06_client {
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .uui-heading-medium {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  .uui-testimonial06_content {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }

  .uui-testimonial06_slide {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .uui-testimonial06_component {
    padding-bottom: 3rem;
  }

  .uui-padding-vertical-xhuge {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .uui-page-padding {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .brix---text-left---t-center {
    width: 85%;
    margin-right: 0px;
    margin-left: auto;
  }

  .brix---grid-footer-v11 {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .brix---footer-small {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .brix---footer-logo-wrapper {
    width: 239px;
  }

  .brix---grid-footer-logo---paragraph {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .brix---footer-bottom-v4 {
    text-align: center;
  }

  .f-navigation-menu-button {
    color: #000;
  }

  .icon {
    color: #fff;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 36px;
    line-height: 52px;
  }

  .divider {
    margin-top: auto;
    margin-bottom: auto;
  }

  .rich-text {
    width: 100%;
    max-width: none;
  }

  .heading-jumbo {
    font-size: 36px;
    line-height: 48px;
  }

  .menu-icon {
    margin-top: 40%;
    background-color: #fff;
  }

  .menu {
    margin-left: 15px;
    background-color: transparent;
    background-image: none;
  }

  .navigation {
    padding-right: 20px;
    padding-left: 20px;
  }

  .menu-button {
    flex: 0 0 auto;
  }

  .menu-button.w--open {
    flex: 0 0 auto;
  }

  .cta-wrap {
    padding-right: 15px;
    padding-left: 15px;
  }

  .status-message.cc-no-data {
    width: 100%;
  }

  .contact-form-grid {
    width: 100%;
  }

  .our-contacts {
    width: 90%;
  }

  .team-members {
    grid-template-areas:
      "."
      "."
      "."
      "."
      "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
  }

  .project-details-grid {
    width: 100%;
  }

  .blog-detail-header-wrap {
    width: 100%;
    max-width: none;
  }

  .blog-item {
    width: 100%;
    max-width: none;
  }

  .paragraph-3 {
    margin-top: 5%;
  }

  .paragraph-5 {
    font-size: 10px;
    line-height: 25px;
  }

  .text-block-3 {
    margin-top: 12%;
  }

  .background {
    border: 1px none #000;
  }

  .column {
    display: none;
  }

  .link {
    width: 25%;
  }

  .italic-text-4 {
    font-size: 20px;
  }

  .section-4 {
    padding-right: 15px;
    padding-left: 15px;
    background-size: 250%, auto;
  }

  .brix---btn-circle-accordion-btn {
    width: 44px;
    height: 44px;
    min-height: 44px;
    min-width: 44px;
  }

  .brix---accordion-right-side {
    margin-right: 0px;
  }

  .brix---paragraph-default {
    font-size: 17px;
    line-height: 28px;
  }

  .brix---accordion-title {
    font-size: 18px;
    line-height: 26px;
  }

  .brix---accordion-v3-card {
    padding: 16px 22px;
  }

  .brix---heading-h2-size {
    font-size: 24px;
    line-height: 34px;
  }

  .brix---mg-bottom-16px {
    margin-bottom: 15px;
  }

  .brix---mg-bottom-48px {
    margin-bottom: 32px;
  }

  .brix---container-default {
    padding-right: 20px;
    padding-left: 20px;
  }

  .brix---heading-h2-size-2 {
    font-size: 24px;
    line-height: 34px;
  }

  .brix---paragraph-default-2 {
    margin-left: auto;
    float: none;
    font-size: 17px;
    line-height: 28px;
  }

  .brix---grid-footer-v11 {
    grid-template-areas:
      "."
      "Area";
  }

  .brix---footer-small {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .brix---container-default-2 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .image-17 {
    width: 50px;
    height: 31px;
  }

  .image-18 {
    width: 30px;
  }

  .image-19 {
    width: 35px;
    height: 29px;
    float: none;
  }

  .brix---footer-logo {
    width: 100%;
    max-width: 240px;
  }

  .brix---footer-bottom-v4 {
    border: 1px none #000;
  }

  .brix---container-default-3 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .brix---footer-wrapper-2 {
    border: 1px none #000;
  }

  .link-5 {
    float: left;
    text-align: center;
  }

  .link-6 {
    float: right;
  }

  .link-7 {
    margin-right: auto;
    margin-left: auto;
    float: none;
  }

  .link-8 {
    width: 78%;
    margin-right: auto;
    margin-left: auto;
    float: none;
  }

  .columns-7 {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px;
  }

  .f-navigation-button {
    display: none;
  }

  .f-navigation-menu-button {
    color: #000;
  }

  .icon {
    background-color: #000;
    color: #fff;
  }

  .f-navigation-button-copy {
    display: none;
  }

  .italic-text-5 {
    font-size: 19px;
  }

  .div-block-10 {
    width: 65%;
    float: right;
  }

  .div-block-11 {
    width: 65%;
    margin-top: 2%;
    float: left;
  }

  .bold-text-10 {
    width: 133%;
    margin-top: 10%;
    float: right;
    text-align: center;
  }

  .link-block {
    width: 100%;
  }

  .column-12 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .image-20 {
    width: 40px;
    height: 29px;
  }

  .link-block-2 {
    width: 20%;
    float: none;
  }

  .column-13 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .column-14 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .column-15 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .column-16 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .column-17 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .image-21 {
    width: 30px;
  }
}

#w-node-c4e7b583-639d-a936-e5ce-f6c5d69355fb-7136b745 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-d0e132bc-1d2c-4309-8f3a-84aa181623e7-7136b745 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d0e132bc-1d2c-4309-8f3a-84aa181623f6-7136b745 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118e6-fb36b74d {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118ec-fb36b74d {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118f2-fb36b74d {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118f8-fb36b74d {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b118fe-fb36b74d {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-ca1212d8-4247-8b87-bc90-543393b11905-fb36b74d {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-_3f61509a-fac7-b0d5-3763-509be705858e-7636b74f {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_3f61509a-fac7-b0d5-3763-509be70585a6-7636b74f {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb08-7636b74f {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb15-7636b74f {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb22-7636b74f {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387918-5d36b751 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387928-5d36b751 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387918-2036b752 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387928-2036b752 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387918-5936b753 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_421df62a-c217-12af-eee8-27f88a387928-5936b753 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38cdc-5e36b754 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38ce3-5e36b754 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-_2878ffd7-26fe-9471-3c4e-7763e2d38cea-5e36b754 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce9803c-ba36b756 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce98043-ba36b756 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce9804a-ba36b756 {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce98051-ba36b756 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce98058-ba36b756 {
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-c28cc763-f708-1e61-f196-6bd9fce9805f-ba36b756 {
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

@media screen and (max-width: 991px) {
  #w-node-ca1212d8-4247-8b87-bc90-543393b118f2-fb36b74d {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b118f8-fb36b74d {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b118fe-fb36b74d {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b11905-fb36b74d {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }

  #w-node-_3f61509a-fac7-b0d5-3763-509be70585a6-7636b74f {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb15-7636b74f {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-cb4222c2-e34c-f4e1-7796-79bb93a2fb22-7636b74f {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }

  #w-node-_421df62a-c217-12af-eee8-27f88a387928-5d36b751 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_421df62a-c217-12af-eee8-27f88a387928-2036b752 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-_421df62a-c217-12af-eee8-27f88a387928-5936b753 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce9804a-ba36b756 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce98051-ba36b756 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce98058-ba36b756 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce9805f-ba36b756 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
}

@media screen and (max-width: 767px) {
  #w-node-c4e7b583-639d-a936-e5ce-f6c5d69355fb-7136b745 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b118ec-fb36b74d {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b118f2-fb36b74d {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b118f8-fb36b74d {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 6;
    grid-row-start: 6;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 7;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b118fe-fb36b74d {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
  }

  #w-node-ca1212d8-4247-8b87-bc90-543393b11905-fb36b74d {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 6;
  }
}

@media screen and (max-width: 479px) {
  #w-node-c28cc763-f708-1e61-f196-6bd9fce98043-ba36b756 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce9804a-ba36b756 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce98051-ba36b756 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce98058-ba36b756 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 5;
    grid-row-start: 5;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 6;
  }

  #w-node-c28cc763-f708-1e61-f196-6bd9fce9805f-ba36b756 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 6;
    grid-row-start: 6;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 7;
  }
}
</style>
