<template>
  <div class="sidebarM" v-animate-css="'slideInRight'" v-if="$store.state.sidebarActive">
    <div class="boton-cerrar" @click.prevent="$store.state.sidebarActive = false">
      <i class="fal fa-times"></i>
    </div>

    <br />

    <div class="menu-sidebarM">
      <!-- Menu Admin  -->
      <ul v-if="$store.state.user.type == 'admin'">
        <li class="small">
          <small style="font-weight: bold; color: #676767">HOME</small>
          <div
            class="item-menu d-flex flex-row"
            style="margin-top: 10px"
            @click="cambiarRuta('dashboardAdmin')"
          >
            <i class="fab fa-modx"></i> <span>Panel </span>
          </div>
        </li>

        <br />
        <br />
        <!-- label  -->

        <li
          class="small"
          data-toggle="collapse"
          href="#collapseExample"
          role="button"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <small style="margin-left: 27px; font-weight: bold; color: #676767">MENU</small>
          <div class="item-menu d-flex flex-row" style="margin-top: 10px">
            <i class="fas fa-users"></i> <span>Usuarios</span>
            <i
              class="fal fa-chevron-right"
              style="font-size: 10px; position: absolute; right: 0px; margin-top: 5px"
            ></i>
          </div>
        </li>
        <div class="collapse" id="collapseExample">
          <ul style="margin-top: 15px; padding-left: 10px">
            <li class="small" @click="cambiarRuta('listaInfluencersAdmin')">
              <div
                class="item-menu d-flex flex-row"
                style="margin-top: 10px; font-size: 14px"
              >
                <i class="fas fa-caret-right"></i> <span>Creador de contenido</span>
              </div>
            </li>
            <li class="small" @click="cambiarRuta('listaGamersAdmin')">
              <div
                class="item-menu d-flex flex-row"
                style="margin-top: 10px; font-size: 14px"
              >
                <i class="fas fa-caret-right"></i> <span>Fan</span>
              </div>
            </li>
          </ul>
        </div>

        <li class="small" @click="cambiarRuta('juegosAdmin')">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-gamepad"></i> <span>Juegos</span>
          </div>
        </li>
        <li class="small">
          <div class="item-menu d-flex flex-row" @click="cambiarRuta('torneosAdmin')">
            <i class="fas fa-trophy-alt"></i> <span>Torneos</span>
          </div>
        </li>
        <li class="small" @click="logout()">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-sign-out"></i
              >
            <span>Cerrar Sesión</span>
          </div>
        </li>

        <!-- <li class="small">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-trophy-alt"></i> <span>Noticias</span>
          </div>
        </li> -->
      </ul>
      <!-- end menu admin  -->
      <ul v-if="$store.state.user.type == 'influencer'">
        <li class="small">
          <small style=" font-weight: bold; color: #676767">HOME</small>
          <div
            class="item-menu d-flex flex-row"
            style="margin-top: 10px"
            @click="cambiarRuta('dashboardInfluencer')"
          >
            <i class="fab fa-modx"></i> <span>Panel </span>
          </div>
        </li>

        <br />
        <br />
        <!-- label  -->

        <li class="small" @click="cambiarRuta('torneosInfluencer')">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-trophy-alt"></i> <span>Torneos</span>
          </div>
        </li>
        <li class="small" @click="cambiarRuta('seguidoresInfluencer')">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-user-plus"></i> <span>Seguidores</span>
          </div>
        </li>
        <li class="small" @click="cambiarRuta('agendaInfluencer')">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-calendar-alt"></i> <span>Agenda</span>
          </div>
        </li>
        <li class="small" @click="cambiarRuta('requestsInfluencer')">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-clipboard-list"></i> <span>Reservas</span>
          </div>
        </li>
        <li class="small" @click="cambiarRuta('incomeInfluencer')">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-sack-dollar"></i>
            <span>Ingresos</span>
          </div>
        </li>
        <li class="small" @click="logout()">
          <div class="item-menu d-flex flex-row">
            <i class="fas fa-sign-out"></i
              >
            <span>Cerrar Sesión</span>
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    logout() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href = "/";
        })
        .catch((error) => {
          toastr.error("Por favor recargue la pagina");
        });
    },
    cambiarRuta(ruta) {
        this.$store.state.sidebarActive = false;
      if (this.$route.name == ruta) {
      } else {
        this.$router.push({ name: ruta });
      }
    },

    irRuta(ruta) {
      this.$router.push({ name: ruta });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .sidebarM {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #080808;
    z-index: 100;

    ul {
    margin-top: 104px;
    .item-menu {
      transition: 0.5s;
      cursor: pointer;
      margin-top: 35px;
      color: white;
      align-items: center;
      i {
        width: 15%;
      }

      &:hover {
        padding-left: 30px;
        border-radius: 10px;
        color: #4d93ce;
        font-weight: 600;
        i {
          color: #4d93ce;
        }
      }
    }
  }



    .boton-cerrar {
      position: absolute;
      right: 38px;
      top: 21px;
      color: white;
      font-size: 31px;
    }

    .dividor {
      height: 1px;
      width: 70%;
      background: #ff5722;
      margin-top: 32px;
      margin-bottom: 30px;
    }

    .menu-sidebarM {
      text-align: left;
      padding: 49px;
      color: white;
      ul {
        margin-top: 20px;
      }

      li.small {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      li.grande {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 13px;
      }
    }
  }
}
</style>
