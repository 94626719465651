<template>
  <div>
    <Nav />
    <Sidebar class="lg" />
    <SidebarM class="xs" />
    <router-view />
  </div>
</template>
<script>
import Nav from "./Nav";
import Sidebar from "./Sidebar";
import SidebarM from "./SidebarM";
import Content from "./Content";
export default {
  components: {
    Nav,
    Sidebar,
    Content,
    SidebarM
  },
};
</script>
