window.$ = window.jQuery = require('jquery');
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './plugins/axios'

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap.js'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import App from './App.vue'
import router from './router'
import store from './store/store'


// alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'
window.toastr = require('toastr')
Vue.use(VueToastr2)

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VAnimateCss from 'v-animate-css';
Vue.use(VAnimateCss);

// Croppa
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
Vue.use(Croppa)


import VueCardFormat from 'vue-credit-card-validation';
Vue.use(VueCardFormat);


import moment from 'moment'
import 'moment/locale/es';
moment.locale('es');

Vue.prototype.moment = moment;

// Variables globales 
import * as tb from "./config/base";
Vue.prototype.$base = tb;

import * as fn from "./config/funtionsglobal";
Vue.prototype.$funtion = fn;


import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"
Vue.use(Skeleton)

import VueSocialauth from 'vue-social-auth'
Vue.use(VueSocialauth, {
    providers: {
        google: {
            clientId: '285907621056-9hqk0ubp7220i74i4bkigtpvlmkgjot5.apps.googleusercontent.com',
            client_secret: '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',
            redirectUri: 'https://beta.ecuatro.es/callback'
        }
    }
});





Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')