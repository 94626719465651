var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Dashboard"},[_c('Nav'),_c('Sidebar',{staticClass:"lg"}),_c('SidebarM',{staticClass:"xs"}),_c('NotificacionesM',{staticClass:"xs"}),_c('div',{staticClass:"contenido"},[(_vm.$store.state.user.type == 'influencer' && _vm.$store.state.user.estado != 1)?[_c('div',{staticClass:"pantallaBloqueo"},[_c('div',{staticClass:"nav d-flex",staticStyle:{"height":"60px","border-bottom":"1px solid rgb(238, 238, 238)","align-items":"center","padding":"0px 197px","justify-content":"space-between"}},[_vm._m(0),_c('div',{staticClass:"title-label lg"},[_c('i',{staticClass:"fab fa-modx mr-1"}),_c('strong',[_vm._v(" "+_vm._s(_vm.$store.state.user.type == "admin" ? "Panel administrativo" : _vm.$store.state.user.type == "gamer" ? "Panel Gamer" : "Creador de contenido")+" ")])]),_c('div',{staticClass:"menu-nav-dashboard d-flex flex-row",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"nombre-user mr-2"},[_vm._v(_vm._s(_vm.$store.state.user.username))]),_c('div',{staticClass:"menu-avatar"},[_c('div',{staticClass:"dropdown"},[_c('div',{attrs:{"type":"button","data-toggle":"dropdown","aria-expanded":"false"}},[(!_vm.$store.state.user.avatar)?_c('div',{staticClass:"avatar",style:({
                        'background-image': 'url(/img/iconos/avatar.png)',
                      })}):_vm._e(),(_vm.$store.state.user.avatar)?_c('div',{staticClass:"avatar d-flex",style:({
                      'background-image':
                        'url(' + _vm.$base.server + _vm.$store.state.user.avatar + ')',
                    })}):_vm._e()]),_c('div',{staticClass:"dropdown-menu"},[(_vm.$store.state.user.type != 'admin')?[_c('hr')]:_vm._e(),_c('a',{staticClass:"dropdown-item",staticStyle:{"color":"#333"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout()}}},[_vm._v("Cerrar Sesión "),_c('i',{staticClass:"fas fa-sign-out ml-2"})])],2)])])])]),_vm._m(1)])]:_vm._e(),_c('router-view')],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"log"},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"122px"},attrs:{"src":"/img/logo/logo-negro.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"centro d-flex flex-column"},[_c('img',{staticStyle:{"width":"152px","margin-bottom":"21px"},attrs:{"src":"/img/iconos/verificando.png","alt":""}}),_c('div',{staticClass:"title-pantall",staticStyle:{"font-size":"35px","text-transform":"uppercase"}},[_vm._v(" Cuenta en proceso de verificación ")]),_c('div',{staticClass:"small",staticStyle:{"font-size":"16px"}},[_vm._v(" El Administrador analizará tus datos para poder aprobar la cuenta. ")])])
}]

export { render, staticRenderFns }