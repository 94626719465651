<template>
  <div class="menuGamers">
    <div
      class="listaMenu d-flex"
      style="height: 100%; border-bottom: 1px solid rgb(53 53 53)"
    >
      <ul
        class="d-flex flex-row"
        style="height: 100%; padding-left: 104px; align-items: center"
        v-if="$store.state.user && $store.state.user.type == 'gamer'"
      >
        <li v-animate-css="'zoomIn'">
          <button
            class="btn"
            :class="{ active: $route.name == 'dashboardGamer' }"
            @click="irRuta('dashboardGamer')"
          >
            <i class="fas fa-user-astronaut mr-2"></i> Streaming
          </button>
        </li>
        <li v-animate-css="'zoomIn'">
          <button
            @click="irRuta('Games')"
            :class="{
              active:
                $route.name == 'Games' ||
                $route.name == 'ChampionshipLista' ||
                $route.name == 'ChampionshipListaTorneos',
            }"
            class="btn"
          >
            <i class="fas fa-gamepad mr-2"></i> Competición
          </button>
        </li>
        <!-- <li v-animate-css="'zoomIn'">
          <button
            @click="irRuta('Championship')"
            :class="{ active: $route.name == 'Championship' }"
            class="btn"
          >
          <i class="fas fa-trophy-alt mr-2"></i> Torneos 
          </button>
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    irRuta(ruta) {
      this.$router.push({ name: ruta });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .listaMenu {
    ul {
      padding-left: 0 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.menuGamers {
  z-index: 5;
  position: relative;

  position: fixed;
  width: 100%;
  background: #181818;
  left: 0;
  top: 60px;
  // padding-left: 112px;
  // padding-right: 112px;
  align-items: center;
  height: 66px;

  .listaMenu {
    // border-bottom: 1px solid rgb(238, 238, 238);
    width: 100%;
    top: 73px;
  }
}
ul {
  height: 100%;
  padding-bottom: 0;
  margin-bottom: 0;

  li {
    .btn {
      // background: #d9d9d9;
      // border-style: dashed;
      border-radius: 48px;
      font-weight: 700;
      font-size: 13px;
      // border: 2px dashed #9c9c9c;
      margin-right: 28px;
      color: #b7b7b7;
      width: 161px;
      font-size: 15px;
    }

    .btn.active {
      color: #2196f3;
      //     color: #673ab7;
      // border: 2px dashed #673ab7;
      //   color: #ff5722;
      //   border: 2px dashed #ff5722;
    }
  }
}
</style>
