<template>
  <div class="Dashboard" style="">
    <Nav />
    <Sidebar class="lg" />
    <SidebarM class="xs" />
    <NotificacionesM class="xs"/>
    
    <div class="contenido">
      <template
        v-if="$store.state.user.type == 'influencer' && $store.state.user.estado != 1"
      >
        <div class="pantallaBloqueo">
          <div
            class="nav d-flex"
            style="
              height: 60px;
              border-bottom: 1px solid rgb(238, 238, 238);
              align-items: center;
              padding: 0px 197px;
              justify-content: space-between;
            "
          >
            <div class="log">
              <img
                src="/img/logo/logo-negro.png"
                alt=""
                class="img-fluid"
                style="width: 122px"
              />
            </div>

            <div class="title-label lg">
              <i class="fab fa-modx mr-1"></i>
              <strong>
                {{
                  $store.state.user.type == "admin"
                    ? "Panel administrativo"
                    : $store.state.user.type == "gamer"
                    ? "Panel Gamer"
                    : "Creador de contenido"
                }}
              </strong>
            </div>
            <div class="menu-nav-dashboard d-flex flex-row" style="align-items: center">
              <div class="nombre-user mr-2">{{ $store.state.user.username }}</div>
              <div class="menu-avatar">
                <div class="dropdown">
                  <div
                    class=""
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div class="avatar" v-if="!$store.state.user.avatar" :style="{
                          'background-image': 'url(/img/iconos/avatar.png)',
                        }">
                    
                    </div>
                    <div
                      class="avatar d-flex"
                      v-if="$store.state.user.avatar"
                      :style="{
                        'background-image':
                          'url(' + $base.server + $store.state.user.avatar + ')',
                      }"
                    ></div>
                  </div>
                  <div class="dropdown-menu">
                    <template v-if="$store.state.user.type != 'admin'">
                      <hr />
                    </template>
                    <a
                      style="color: #333"
                      class="dropdown-item"
                      href="#"
                      @click.prevent="logout()"
                      >Cerrar Sesión <i class="fas fa-sign-out ml-2"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="centro d-flex flex-column">
            <img
              src="/img/iconos/verificando.png"
              alt=""
              style="width: 152px; margin-bottom: 21px"
            />
            <div class="title-pantall" style="font-size: 35px; text-transform: uppercase">
              Cuenta en proceso de verificación
            </div>
            <div class="small" style="font-size: 16px">
              El Administrador analizará tus datos para poder aprobar la cuenta.
            </div>
          </div>
        </div>
      </template>
      <router-view />
    </div>
  </div>
</template>
<script>
import Nav from "./Nav.vue";
import NotificacionesM from "./NotificacionesM.vue";
import Sidebar from "./Sidebar.vue";
import SidebarM from "./SidebarM.vue";
export default {
  components: {
    Nav,
    Sidebar,
    SidebarM,
    NotificacionesM
},
  methods:{
     
     logout() {
     this.$store
       .dispatch("destroyToken")
       .then((response) => {
         window.location.href = "/";
       })
       .catch((error) => {
         toastr.error("Por favor recargue la pagina");
       });
   },
   }
};
</script>

<style lang="scss" scoped>
   @media(max-width:768px){
    .pantallaBloqueo{
      .nav {
        padding: 10px !important;
      }
    }
   }
</style>
<style lang="scss" scoped>
@media(max-width:768px){
  .Dashboard {
    --background-color: #ff5722;
  background-image: -webkit-linear-gradient(300deg,var(--background-color) 20px,transparent 220px);
    background-image: linear-gradient(150deg,var(--background-color) 20px,transparent 220px);

  }
 .contenido{
    margin-left: 14px !important;
    padding-right: 17px !important;
    padding-left: 17px !important;
    padding-top: 113px !important;
    min-height: 100vh !important;
    height: 100% !important;
    margin-right: 14px !important;

   
}
}
 
</style>

<style lang="scss">

.modal .modal-header .modal-title {
  color:white;
}
.card .card-footer[data-v-9bfb1bc8] {
    padding: 7px;
    color: white;
    background: #292929;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #181818;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    color: white !important;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #282828;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #252525;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}


.btn-default {
  color: white;
    background: #2196F3 !important;
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #d9d9d9;
    background-color: #161616;
    background-clip: padding-box;
    border: 1px solid #323232;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

body {
  background: #121212 !important;
}

</style>
<style lang="scss" scoped>
.nav {
  .nombre-user {
    font-weight: 600;
    color: #2a2a2a;
  }
  .avatar {
    height: 44px;
    width: 44px;
    background-position: center;
    background-size: cover;
    // background: #673ab7;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #eee;
    margin-left: 15px;
  }
}

.pantallaBloqueo {
  background: white;
  position: fixed;
  left: 0;
  z-index: 10000;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
}

.centro {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.contenido {
  margin-left: 264px;
  padding-right: 18px;
  padding-left: 17px;
  padding-top: 113px;
  min-height: 100vh;
  height: 100%;
  margin-right: 10px;
}
</style>
